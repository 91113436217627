import {
  Box,
  SimpleGrid,
  Select,
  AbsoluteCenter
} from '@chakra-ui/react';
import { useSelector } from "react-redux";
import ACGridItem from '../GridItem';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import ReactPaginate from 'react-paginate';
import './index.css';

const Summary = ({ gridItems = [], itemsPerPage, type }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [selectedValue, setSelectedValue] = useState('All');
  const [currentView, setCurrentView] = useState([]);
  const [groupedList, setGroupedList] = useState(null);
  const [currentPage, setCurrentPage] = useState(Math.round(itemOffset / itemsPerPage));

  const posts = useSelector((state) => state.posts);

  useEffect(() => {
    gridItems.forEach(gridItem => {
      const matchingPost = posts.find(post => post.id === gridItem.id);
      if (matchingPost) {
        gridItem.reaction = matchingPost.reaction;
      }
    });
  }, []);


  const endOffset = itemOffset + itemsPerPage;
  const currentItems = gridItems.slice(itemOffset, endOffset)
  const pageCount = selectedValue === 'All'
    ? Math.ceil(gridItems.length / itemsPerPage)
    : Math.ceil(currentView.length / itemsPerPage);
  const groupedDates = groupedList ? Object.keys(groupedList) : [];

  useEffect(() => {
    const newGroupedItems = type === 'schedule'
      ? _.groupBy(gridItems, 'schedule_date')
      : null;

    setGroupedList(newGroupedItems);
  }, [gridItems, type])

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % gridItems.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  function Items(currentItems) {

    const itemsListView = type === 'schedule' && selectedValue !== 'All' && groupedList
      ? groupedList[selectedValue]
      : currentItems.currentItems;

    return (
      <>
        {Array.isArray(itemsListView) &&
          itemsListView.filter(r => (r.data?.body !== 'string' || r.data?.output !== 'string' || r.topic === 'general')).map((item, i) => (
            <ACGridItem data={item} type={type} key={i} />
          ))}
      </>
    );
  }

  useEffect(() => {
    setCurrentPage(0);
  }, [tabIndex]);

  const handleSelectedValueChange = (value) => {
    setSelectedValue(value)

    if (value !== 'All') {
      const newListView = groupedList[value]
      setCurrentView(newListView)
    }
  }

  return (
    <>
      {type === 'schedule' && (
        <Box m={5}>
          <h1>{selectedValue}</h1>
          <Select placeholder='' value={selectedValue} onChange={(e) => handleSelectedValueChange(e.target.value)}>
            <option value='All'>All</option>
            {groupedDates.map((d, i) => (<option key={i} value={d}>{d}</option>))}

          </Select>
        </Box>

      )}


        <Items currentItems={currentItems} />

      <Box position='relative' h='100px'>
        <AbsoluteCenter p='4' color='white' axis='both'>
          <ReactPaginate
            breakLabel="..."
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            renderOnZeroPageCount={null}
            forcePage={currentPage}
            previousLabel="Previous"
            nextLabel="Next"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            marginPagesDisplayed={2}
            containerClassName="pagination"
            activeClassName="active"
          />
        </AbsoluteCenter>
      </Box>
    </>

  );
}

export default Summary;