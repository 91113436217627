import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Container,
  Flex,
  HStack, Icon,
  IconButton,
  useBreakpointValue,
  Heading,
  Stack,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem
} from '@chakra-ui/react'
import { FiHelpCircle, FiMenu, FiSearch, FiSettings } from 'react-icons/fi'
import { MdGroupWork } from "react-icons/md";
import { NavLink } from "react-router-dom";

export const AppNavBar = ({ auth }) => {
  const isDesktop = useBreakpointValue({ base: false, lg: true })

  return (
    <Box as="section" pb={{ base: '12', md: '24' }}>
      <Box as="nav" bg="bg-surface" boxShadow="sm" borderRadius={7} maxW={'86rem'} margin={'auto'} p={[4, 2, 4, 4]}>
        <Container py={{ base: '3', lg: '4' }} px={0}>
          <Flex justify="space-between">
            <HStack spacing="1">
              <Icon as={MdGroupWork} w={8} h={8} color='black' />
              <Heading as='h3' size='xm' color='black'>Artificial Compute</Heading>
            </HStack>
            <HStack spacing="1">
              {isDesktop && (
                <Text color={'black'}>Closed Early Beta</Text>
              )}
            </HStack>
            {isDesktop ? (

              <HStack spacing="4">
                <Stack direction='row' spacing={4} align='right'>
                  <Button color='black'>
                    {auth?.authenticated && (
                      <Text fontSize="1.25rem" color='black'>
                        {auth?.user?.email}
                      </Text>
                    )}
                    <NavLink
                      to="login"
                      className="onboard-step-2">
                      Log In
                    </NavLink>

                  </Button>
                </Stack>
              </HStack>
            ) : (
              <Menu>
                <MenuButton
                  as={IconButton}
                  icon={<HamburgerIcon />}
                  variant="outline"
                />
                <MenuList color="black">
                  <MenuItem as="a" href="/login">Log In</MenuItem>
                </MenuList>
              </Menu>
            )}
          </Flex>
        </Container>
      </Box>
    </Box>
  )
}
