import { createSlice } from '@reduxjs/toolkit';

export const eventsSlice = createSlice({
  name: 'events',
  initialState: [],
  reducers: {
    setEvents: (state, action) => {
      const eventsArray = action.payload.data;
      state = [...eventsArray];
      return state;
    },
    addNewEvent: (state, action) => {
      const newEvent = action.payload;
      return [...state, newEvent];
    },
    updateEvent: (state, action) => {
      const { id } = action.payload;

      const index = state.findIndex(e => e.posts.id === id);
      if (index !== -1) {
        return [
          ...state.slice(0, index),
          {
            ...state[index],
            posts: action.payload
          },
          ...state.slice(index + 1)
        ];
      }
    },
    deleteEvent: (state, action) => {
      const newEvents = state.filter(event => event.id !== action.payload);
      state = [...newEvents];
      return state;
    }
  },
})

// this is for dispatch
export const { setEvents, addNewEvent, deleteEvent, updateEvent } = eventsSlice.actions;

// this is for configureStore
export default eventsSlice.reducer;
