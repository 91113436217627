
const signUp = async (payload) => {
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    "email": payload.email,
    "password": payload.password,
    "options": {
      "data": {
        "uiuid": payload.user_metadata.uiuid,
        "organization": payload.user_metadata.organization,
        "name": payload.user_metadata.name,
        "org_uiuid": payload.user_metadata.org_uiuid,
      },
      "emailRedirectTo": `${process.env.REACT_APP_WEB_URL}/setup/${payload.user_metadata.uiuid}`
    }
  });

  const requestOptions = {
    method: 'POST',
    headers: reqHeaders,
    body: raw,
    redirect: 'follow'
  };

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/auth/signup`, requestOptions);
  return result.json();
};

const signIn = async (payload) => {
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    "email": payload.email,
    "password": payload.password,
  });

  const requestOptions = {
    method: 'POST',
    headers: reqHeaders,
    body: raw,
    redirect: 'follow'
  };

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/auth/signIn`, requestOptions);
  return result.json();
}

const signOut = async (payload) => {
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: 'GET',
    headers: reqHeaders,
    redirect: 'follow'
  }

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/auth/signout`, requestOptions);
  return result.json();
}

const startTwitterAuth = async (auth) => {
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");
  reqHeaders.append("Authorization", `Bearer ${auth.session.access_token}`);

  const requestOptions = {
    method: 'GET',
    headers: reqHeaders,
  }

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/social/auth`, requestOptions)
  return result.json();
}

const startTwitterRequestToken = async (payload, auth) => {
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  reqHeaders.append("Authorization", `Bearer ${auth.session.access_token}`);

  const requestOptions = {
    method: 'GET',
    headers: reqHeaders,
  }

  const result = await fetch(
    `${process.env.REACT_APP_SERVICE_URL}/v1/social/callback/${payload.storedRequestToken}/${payload.storedRequestTokenSecret}/${payload.query.oauth_verifier}/`,
    requestOptions
  )
  return result.json();
}

const startTwitterVerify = async (payload) => {
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  const requestOptions = {
    method: 'GET',
    headers: reqHeaders,
  }

  const result = await fetch(
    `${process.env.REACT_APP_SERVICE_URL}/v1/social/verify/${payload.oauthAccessToken}/${payload.oauthAccessTokenSecret}`,
    requestOptions
  )
  return result.json();
}

const postTwitterMessage = async (payload) => {
  const reqHeaders = new Headers();
  // reqHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  reqHeaders.append("Content-Type", "application/json");
  reqHeaders.append("Authorization", `Bearer ${payload.oauthAccessToken}`);

  const requestOptions = {
    method: 'POST',
    headers: reqHeaders,
    body: JSON.stringify({ 'status': payload.text })
  }

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/social/twitter/${payload.oauthAccessToken}/${payload.oauthAccessTokenSecret}`, requestOptions)
  return result.json();
}

export default {
  signUp,
  signIn,
  signOut,
  startTwitterAuth,
  startTwitterRequestToken,
  startTwitterVerify,
  postTwitterMessage
};
