import React, { useState } from 'react';
import { Box, Button, Heading, Badge, Input, FormControl, InputGroup, Text, useColorModeValue, Progress, useToast } from '@chakra-ui/react';
import { HiCog } from 'react-icons/hi';
import { CardContent } from './CardContent';
import { CardWithAvatar } from './CardWithAvatar';
import { UserInfo } from './UserInfo';
import TopicsApi from '../../methods/TopicsApi';
import { v4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { supabase } from '../../../src/methods/Supabase';
import {
  FiGrid,
  FiAlignRight,
  FiCalendar
} from 'react-icons/fi';
import { SidebarButton } from './SidebarButton';

const { addTopic } = TopicsApi;

const handleSubmit = async (event, auth, setCreatingContent, showToastMessage, value, setValue) => {
  setCreatingContent(true)

  const topicPayload = {
    value: value,
    uiuid: v4(),
    user_uiuid: auth.auth.user_metadata.uiuid,
    author: 'Sandra Ae',
    data: { industry: auth.userIndustry }
  };

  const response = await addTopic(topicPayload, auth?.auth);
  if (response) {
    showToastMessage('info', 'Working on your new content, Boss! It may take up to 2 minutes to see this content.')
    openChannelAndWaitForCreativeResponse(value, setCreatingContent, showToastMessage)
    setValue('')
  }
}

const openChannelAndWaitForCreativeResponse = async (value, setCreatingContent, showToastMessage) => {
  let responseReceived = false;
  let response = null;

  const contentChannel = supabase
    .channel('new content realtime')
    .on('postgres_changes', {
      event: 'INSERT',
      schema: 'public',
      table: 'creative'
    },
      (payload) => {
        const { topic } = payload.new
        const refreshAfterInsert = () => window.location.reload();

        if (value === topic) {
          responseReceived = true;
          response = payload;

          setCreatingContent(false)
          showToastMessage('success', 'Sandra has successfully created new content for you!')
          setTimeout(refreshAfterInsert, 6000);
        }
      }
    )
    .subscribe();

  while (!responseReceived) {
    await new Promise((resolve) => setTimeout(resolve, 100));
  }

  contentChannel.unsubscribe();
  return response;
}

export const AgentFullWidthCard = (props) => {
  const auth = props;
  const { ae, userIndustry } = props;
  const [creatingContent, setCreatingContent] = useState(false);
  const [value, setValue] = React.useState('');
  const navigate = useNavigate();
  const handleChange = (event) => setValue(event.target.value)
  const toast = useToast();

  const showToastMessage = (toastStatus, title) => toast({
    position: 'top',
    title,
    status: toastStatus,
    duration: 5000,
    isClosable: true
  })
  
  const aeProfile = {
    social:{
      status: 'Early Beta',
      email: 'sandra@artificialcompute.com',
      link: '01s.ac/sandra',
      img:32, 
      name:'Sandra Ae', 
      title:'Artificial Social Media Manager'},
    web:{
      status: 'Coming Soon',
      email: 'david@artificialcompute.com',
      img:5, name:'David Ae', 
      link: '01s.ac/david',
      title:'Artificial Web Developer'},
    office:{
      status: 'Coming Soon',
      email: 'cesar@artificialcompute.com',
      img:4,  
      link: '01s.ac/cesar',
      name:'Cesar Ae', 
      title:'Artificial Office Manager'
    },
    sales:{
      status: 'Coming Soon',
      email: 'jess@artificialcompute.com',
      img:19, 
      link: '01s.ac/jess',
      name:'Jess Ae', 
      title:'Artificial Sales Assistant'
    }
  }
  return (
    <Box as="section" pt="20" pb="12" position="relative">
      <Box position="absolute" inset="0" height="32" bg="gray.800" />
      <CardWithAvatar
        className='sandra-card'
        maxW="xl"
        avatarProps={{
          src: `./avatars/PNG/${aeProfile[ae]?.img || 32}.png`,
          name: aeProfile[ae]?.name,
        }}
        action={
          <>
          
          </>
          
        }
      >
        <CardContent>
          <Heading size="lg" fontWeight="extrabold" letterSpacing="tight" id='sandra-shepherd-tour'>
            {aeProfile[ae]?.name}
            <Badge variant='solid' ml={2} colorScheme='pink'>
            {aeProfile[ae]?.status}
            </Badge>
          </Heading>
          <Text color={useColorModeValue('gray.600', 'gray.400')}>
            {aeProfile[ae]?.title}
          </Text>
          <Box w="100%">
            <UserInfo website={aeProfile[ae]?.link} aeEmail={aeProfile[ae]?.email} />
            {ae === 'social' &&
            <FormControl>
              <InputGroup>
                <Input
                  onChange={handleChange}
                  value={value} 
                  mt={2}
                  width="100%"
                  size="lg"
                  variant="outline"
                  placeholder="Hello, Boss. Any other topics?"
                  onKeyDown={e => e.key === 'Enter'
                    ? handleSubmit(e, auth, setCreatingContent, showToastMessage, value, setValue)
                    : null}
                />
              </InputGroup>
            </FormControl>
            }
          </Box>
          {creatingContent
            ? <><Progress isIndeterminate style={{ marginTop: '14px' }} /><br />Creating content...</>
            : <></>}
        </CardContent>
        {ae === 'social' &&
        <Box>
        <><Button size="sm" m={2} onClick={() => navigate('/topics')} leftIcon={<FiAlignRight />}>
            Topics
          </Button>
          <Button size="sm" m={2} onClick={() => navigate('/tools')} leftIcon={<FiGrid />}>
            Tools
          </Button>
          <Button size="sm" m={2} onClick={() => navigate('/dashboard')} leftIcon={<FiCalendar />}>
            Schedule
          </Button></>
        </Box>
        }
      </CardWithAvatar>
    </Box>
  )

}