const getAllReactions = async () => {
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: 'GET',
    headers: reqHeaders
  }

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/reactions`, requestOptions);
  return result.json();
}

const getReactionById = async (payload) => {
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: 'GET',
    headers: reqHeaders
  }

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/reactions/${payload.id}`, requestOptions);
  return result.json();
}

const addLike = async (payload) => {
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify(payload)

  const requestOptions = {
    method: 'POST',
    headers: reqHeaders,
    body: raw
  }

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/reactions`, requestOptions);
  return result.json();
}

const deleteReactionById = async (payload) => {
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: 'DELETE',
    headers: reqHeaders,
  }

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/reactions/${payload.id}`, requestOptions);
  return result.json();
}

const updateLike = async (payload) => {
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");
  const raw = JSON.stringify(payload);

  const requestOptions = {
    method: 'PUT',
    headers: reqHeaders,
    body: raw,
    redirect: 'follow'
  };

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/reactions/${payload.id}`, requestOptions);
  return result.json();
}

export default {
  getAllReactions,
  getReactionById,
  addLike,
  deleteReactionById,
  updateLike
}