import { createSlice } from '@reduxjs/toolkit';

const initialState =  { id: null, user: { email: 'guest', user_metadata: { organization: 'guest' } }, authenticated: false };

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    addAuth: (state, action) => {
      const { id, email, email_confirmed_at, aud, user_metadata } = action.payload.data.user;
      const { session, profile = null } = action.payload.data;
      const { subscription = false } = action.payload.data;
      const auth = {
        id: id || session?.user?.id,
        email,
        email_confirmed_at,
        user_metadata,
        authenticated: aud ? true : false,
        subscription,
        profile,
        session
      };

      state = { ...auth };
      return state;
    },
    clearAuth: () => initialState
  },
})

// this is for dispatch
export const { addAuth, clearAuth } = authSlice.actions;

// this is for configureStore
export default authSlice.reducer;
