import React from 'react'
import { Button, Center, HStack, Icon, Square, Text, VStack, Box } from '@chakra-ui/react'
import { FiUploadCloud } from 'react-icons/fi'
import { useToast } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { FileUploader } from "react-drag-drop-files";
import { supabase } from '../../methods/Supabase';
import { fetchGallery } from '../../utils/fetchGallery';
import { images } from '../../features/gallery/gallerySlice';

const fileTypes = ["JPEG", "PNG", "GIF", "JPG"];

const DragDrop = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const toast = useToast();

  const showToastMessage = (toastStatus, title) => toast({
    position: 'top',
    title,
    status: toastStatus,
    duration: 5000,
    isClosable: true
  })

  const handleChange = async (file) => {
    const userId = auth?.user_metadata.uiuid || '';
    const filename = `${userId}/${Date.now()}`;
    const { data, error } = await supabase.storage
      .from("users_files")
      .upload(filename, file[0], {
        cacheControl: "3600",
        upsert: false,
      });

    if (error) {
      showToastMessage('error', 'There was a problem uploading the file')
    }

    if (data) {
      showToastMessage('success', 'Image uploaded successfully!');
      const fetchedImages = await fetchGallery(userId);
      dispatch(images(fetchedImages));
    }
  };

  const onTypeError = (err) => showToastMessage('error', `Media must be in JPEG, PNG, GIF, or JPG format.`)
  const onSizeError = (file) => showToastMessage('error', `${file}! Image must be under 2MB in size.`)

  return (
    <Box display={'contents'}>
      <FileUploader
        width={'100%'}
        multiple={true}
        handleChange={handleChange}
        name="file"
        types={fileTypes}
        maxSize={2}
        classes="dropzone"
        label="Upload your files here"
        onTypeError={onTypeError}
        onSizeError={onSizeError}
      />
    </Box>
  )
}

export default DragDrop