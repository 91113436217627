import { useState } from 'react';
import { useSelector } from "react-redux";
import {
  Icon,
  Menu,
  MenuButton,
  Text,
  IconButton,
  Box,
  Flex,
  Spacer,
  Heading
} from '@chakra-ui/react'
import { ChevronLeftIcon, HamburgerIcon } from '@chakra-ui/icons';
import { MdGroupWork } from 'react-icons/md';
import MediaGallery from '../../components/Media/MediaGallery';
import { Sidebar } from "../../components/ACSidebars";
import navigationData from "../../data/navigation";
import { MediaDragDrop } from '../../components/ACMedia';

export const Media = () => {
  const [closeSideBar, setCloseSideBar] = useState(false);
  const auth = useSelector((state) => state.auth);

  if (!auth || auth.authenticated === false) {
    return (<>
      <h1>Shucks, something went wrong!</h1>
      <pre>{JSON.stringify(auth, 2)}</pre>
    </>);
  }

  return (
    <>
      <Box position="sticky" top="0" zIndex="1" bg="white" p={4}>
        <Flex>
          <Box><Icon as={MdGroupWork} w={8} h={8} color='black' /></Box>
          <Spacer />
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={closeSideBar ? <HamburgerIcon /> : <ChevronLeftIcon />}
              // varia nt={"ghost"}
              onClick={() => setCloseSideBar(closeSideBar ? false : true)}
            />
            {/* You can add MenuList and MenuItems here for dropdown options */}
          </Menu>
        </Flex>
      </Box>
      <Flex>
        <Box position="sticky" top="0" height="calc(100vh - 4rem)" width={'max-content'}
          display={closeSideBar ? 'none' : 'block'} >
          <Sidebar navigationData={navigationData} />
        </Box>
        <Box width={closeSideBar ? '100%' : '80%'} height="full" m={5}>
          <Heading>Media</Heading>
          <Text>
            Upload and manage your media here.
            <br />
            File must be under 2MB in size.
          </Text>
          <Box
            as="section"
            bg="bg.surface"
            py={{
              base: '4',
              md: '8',
            }}
          >
            <MediaDragDrop />
          </Box>
          <MediaGallery />
        </Box>
      </Flex>
    </>
  )
}
