import { Box, Container, Flex, VStack, StackDivider, Heading } from '@chakra-ui/react'
import { useState, useEffect, useCallback } from 'react'
import { useSelector } from "react-redux";
import { Navbar } from '../../components/Navs/Navbar';
import { TopNavbar } from "../../components/Navs/TopNavbar";
import jwtDecode from 'jwt-decode';
import { useLocation } from "react-router-dom";
import { Widget } from '@typeform/embed-react';
import { useDispatch } from 'react-redux';
import { addAuth } from '../../features/auth/authSlice';
import LocalStorage from '../../methods/LocalStorage';
import PricingPage from '../../components/Pricing/PricingPage';

export const Setup = () => {
  const [accessToken, setAccessToken] = useState('');
  const [accessTokenObject, setAccessTokenObject] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const uiuid = location.pathname.split('/')[2];
  const auth = useSelector((state) => state.auth);
  const { loadState, saveState } = LocalStorage;

  const saveAuthToStorage = useCallback(async (uiuid, authPayload) => {
    await saveState(uiuid, authPayload);
    return true;
  }, []);

  useEffect(() => {
    const authPayload = loadState('ac_batptl');
    if (location.hash && location.hash.split('=').length > 2) {
      const accessTokenData = location.hash.split('=')[1].split('&')[0];
      setAccessToken(accessTokenData);
      if (accessTokenData || accessToken) {

        const tokenObject = jwtDecode(accessTokenData || accessToken);
        setAccessTokenObject(tokenObject);
        const authPayload = { data: { user: tokenObject, session: { access_token: accessTokenData || accessToken } }, id: authPayload.id };
        //check if user is already has this token set...we need to remove on logout auto log the user out

        saveAuthToStorage('ac_batptl', authPayload);
        dispatch(addAuth(authPayload));
      }
    }

    if (authPayload && uiuid === 'tybus') {
      authPayload.data.subscription = true;
      dispatch(addAuth(authPayload));
    }
  }, [])

  if (!auth || auth.authenticated === false) {
    return (<></>);
  }
  if (auth && !auth.subscription || (auth.subscription === undefined || auth.subscription === null || auth.subscription === false)) {
    return (<>
      <Box bg="blackAlpha.900">
        <VStack
          divider={<StackDivider borderColor='gray.200' />}
          spacing={4}
          align='stretch'
        >
          <Box h='40px'>

          </Box>
          <Box >
            <Container maxW="container.xl" centerContent>
              <Heading size="lg" fontWeight="extrabold" letterSpacing="tight" color="white">
                Early Adopter Pricing
              </Heading>
              <Heading m={10} size="sm" fontWeight="bold" letterSpacing="tight" color="white" textAlign={'center'}>
                IMPORTANT: Don't forget to click on "Apply Code" on the next screen for the discount ❤️🤖
              </Heading>
            </Container>
          </Box>
        </VStack>


      </Box>
      <PricingPage></PricingPage>
    </>);
  }

  return (
    <Box
      height="full"
      width="full">
      <TopNavbar />
      <Flex height="100vh">
        <Box
          height="full"
          width={{
            md: '14rem',
            xl: '18rem',
          }}
          display={{
            base: 'none',
            lg: 'initial',
          }}
          overflowY="auto"
          borderRightWidth="1px"
        >
        </Box>
        <Flex justify="center" w={'100%'}>
          <Widget id={process.env.REACT_APP_TF_ONBOARDING} style={{ width: '70%' }} className="beta-setup-form" tracking={{ utm_campaign: auth.user_metadata.uiuid, utm_medium: 'web-beta' }} />
        </Flex>
      </Flex>
    </Box>
  )
}
