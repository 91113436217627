function loadState(key) {
    try {
        const serializedState = localStorage.getItem(key);
        if (!serializedState) return undefined;
        return JSON.parse(serializedState);
    } catch (e) {
        return undefined;
    }
}

async function saveState(key, state) {

    const lockList = [];
    const value = lockList.includes(key) ? 'locked' : state;
    try {
        const serializedState = JSON.stringify(value);
        localStorage.setItem(key, serializedState);
    } catch (e) {
        // Ignore
    }
}
export default {
    loadState,
    saveState
};
