import React from 'react';
import {
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Button,
    useDisclosure
  } from '@chakra-ui/react'

function ChatDrawer({type}) {
    const [size, setSize] = React.useState('')
    const { isOpen, onOpen, onClose } = useDisclosure()
  
    const handleClick = (newSize) => {
      setSize(newSize)
      onOpen()
    }
  
    const sizes = ['xs', 'sm', 'md', 'lg', 'xl', 'full'];
    const employees ={
      social: {
        'name': 'Social Media', 
        'icon': 'twitter',
        'id': 'cln3ztltj202150ojjtzgslxh8',
        'ae': 'Social Media Manager'
      },
      office: {
        'name': 'Office',
        'icon': 'slack',
        'id': 'cln45m80h505800plcfc59d4sl',
        'ae': 'Office Manager',
      },
      web:{
        'name': 'Web',
        'icon': 'zap',
        'id': 'cln454i8v68060nmk8v65cmpw',
        'ae': 'Web Developer',
      },
      sales:{
        'name': 'Sales',
        'icon': 'zap',
        'id': 'cln4uqmak95680pl70qx9r7q7',
        'ae': 'Sales Assistant',
      }
    };
  
    return (
      <>

          <Button
            onClick={() => handleClick('xl')}
            key={'xl'}
            m={4}
          >{` ${employees[type].ae}`}</Button>
   
        <Drawer onClose={onClose} isOpen={isOpen} size={size}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>{`${employees[type].name}`}</DrawerHeader>
            <DrawerBody>
            <zapier-interfaces-chatbot-embed is-popup="false" chatbot-id={employees[type].id} height="600px" width="100%"></zapier-interfaces-chatbot-embed>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </>
    )
}

  export default ChatDrawer;