import React, { useState } from 'react';
import {
  Box,
  Flex,
  Button,
  Container,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Avatar,
  Textarea,
  useDisclosure,
} from '@chakra-ui/react';
import { FacebookIcon, TwitterIcon, WhatsAppIcon } from './SocialIcons'
import { AddIcon } from '@chakra-ui/icons';
import { isEmpty } from 'lodash';
import { FaTwitter } from 'react-icons/fa';
import Gateway from '../../../methods/Gateway';
import TweetsApi from '../../../methods/TweetsApi';
import ConfettiExplosion from 'react-confetti-explosion';
import { useDispatch, useSelector } from "react-redux";
import { FaBlog } from 'react-icons/fa';
import { useNavigate, Link } from "react-router-dom";
import SocialMediaLinkButtons from './SocialMediaLinkButtons';

const { postTwitterMessage } = Gateway;
const { addTweet } = TweetsApi;

const Summary = ({ twitterUser, blogs, auth, toggleShowDashboard, userIndustry }) => {
  const [text, setText] = useState('');
  const [isExploding, setIsExploding] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const tools = useSelector((state) => state.tools);
  const twitterTool = tools.find(tool => tool.type === 'twitter');
  const oauthAccessToken = twitterTool?.config?.toolAccess?.oauthAccessToken;
  const oauthAccessTokenSecret = twitterTool?.config?.toolAccess?.oauthAccessTokenSecret;
  const blog = blogs?.filter(blog => blog?.status === '1')[0];
  const navigate = useNavigate();

  const handleOpenBlog = (blog) => {
    window.open(blog.url, '_blank');
  }

  const handleSendTweet = async () => {
    if (!text.length) {
      // handle empty message notification
      const result = await addTweet({ body: 'Hello World' }, auth);
      setIsExploding(true);
      return result;
    }
    if (oauthAccessToken) {
      // handle loading with spinner
      //const tweetResponse = await postTwitterMessage({ text, oauthAccessToken, oauthAccessTokenSecret });
      const result = await addTweet({
        body: {
          text,
          oauthAccessToken,
          oauthAccessTokenSecret
        }
      }, auth);
      setIsExploding(true);
      handleOnClose();
      return result;
    }
  }

  const handleOnClose = (e) => {
    onClose();
    setText('');
  }


  const handleInputChange = (e) => {
    let inputValue = e.target.value;
    setText(inputValue);
  }


  return (
    <>
      <Box
        as="section"
        py={{
          base: '4',
          md: '0',
        }}
      >
        <Container px="0px">
          <SocialMediaLinkButtons toggleShowDashboard={toggleShowDashboard} />
        </Container>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex direction="column">
              <Flex align="center">
                <Avatar src={twitterUser?.photo} />
                <Box ml="3">
                  <Text fontWeight="bold" fontSize="xl">{twitterUser?.screen_name}</Text>
                </Box>
              </Flex>
              <Text mt="5" fontSize="sm">Enter your tweet and click 'Create'</Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea
              value={text}
              onChange={handleInputChange}
              size="md"
            />
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>Close</Button>
            <Button colorScheme="twitter" onClick={handleSendTweet}>Create</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default Summary;