import { v4 } from "uuid";


const getAllEvents = async (auth) => {
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");
  reqHeaders.append("Authorization", `Bearer ${auth.session.access_token}`);

  const requestOptions = {
    method: 'GET',
    headers: reqHeaders
  }

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/events`, requestOptions);
  return result.json();
}

const getEventById = async (payload, auth) => {
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");
  reqHeaders.append("Authorization", `Bearer ${auth.session.access_token}`);

  const requestOptions = {
    method: 'GET',
    headers: reqHeaders
  }

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/events/${payload.id}`, requestOptions);
  return result.json();

}

const addEvent = async (payload, auth) => {
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");
  reqHeaders.append("Authorization", `Bearer ${auth.session.access_token}`);
  payload.uiuid = payload.type + '-' + payload.user_uiuid;
  const raw = JSON.stringify(payload);

  const requestOptions = {
    method: 'POST',
    headers: reqHeaders,
    body: raw
  }

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/events`, requestOptions);
  return result.json();
}

const deleteEventById = async (payload, auth) => {
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");
  reqHeaders.append("Authorization", `Bearer ${auth.session.access_token}`);

  const requestOptions = {
    method: 'DELETE',
    headers: reqHeaders,
  }

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/events/${payload.id}`, requestOptions);
  return result.json();
}


const updateEvent = async (payload, auth) => {
 // thumbs up / down funcionality for work items
 

 const newEvent = payload;


 const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");
  reqHeaders.append("Authorization", `Bearer ${auth.session.access_token}`);
  payload.uiuid = payload.type + '-' + payload.user_uiuid;
  const raw = JSON.stringify(payload);

  const requestOptions = {
    method: 'PUT',
    headers: reqHeaders,
    body: raw
  }

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/events/${payload.id}`, requestOptions);
  return result.json();


 
}

function Event(payload, auth){

  const authValues = Object.keys(auth).includes('data') ? auth.data : auth;

  const { 
    status, 
    type, 
    toolUser,
    toolAccess
  } = payload;

  const {user} = authValues;
  const {user_metadata} = user;
  const {uiuid} = user_metadata;
  this.user_uiuid = uiuid;
  this.uiuid = v4();
  this.config = {
    toolUser,toolAccess
  }
  this.name = toolUser.screen_name;
  this.type = type;
  this.status = status;

}

export default {
  getAllEvents,
  getEventById,
  addEvent, 
  updateEvent,
  deleteEventById,
  Event
}