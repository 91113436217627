import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { supabase } from '../../../../src/methods/Supabase'
import {
  Box,
  Text,
  Avatar, 
  Flex, 
  HStack,
  Icon,
  Spacer, Image, Badge, Spinner,Stack,
  Button,
  Tag,
  Wrap,
  useColorModeValue,
} from '@chakra-ui/react';
import { HiCash, HiLocationMarker, HiShieldCheck } from 'react-icons/hi';
import { useToast } from '@chakra-ui/react'
import { BiHeart, BiSolidHeart } from 'react-icons/bi';
import { GrCopy } from 'react-icons/gr';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { v4 as uuidv4 } from 'uuid';
import { IconContentDrawer } from "../../../components/ACDrawer";
import ReactionsApi from '../../../methods/ReactionsApi';
import PostsApi from '../../../methods/PostsApi';
import { updateContent } from '../../../features/content/contentSlice';
import { updatePost as reduxUpdatePost } from '../../../features/post/postSlice';
import { Card } from './card'
import { CustomerReviews } from './customerreviews'
const ACGridItem = ({ data, type }) => {
  const imagesBucketURL = `${process.env.REACT_APP_SUPABASE_PROJECT_URL}/storage/v1/object/public/users_files/`;

  const [isLoading, setIsLoading] = useState(false);
  const [isLiked, setIsLiked] = useState(data.reaction === 'like');
  const auth = useSelector((state) => state.auth);
  const userInfo = auth?.user_metadata;
  const reactions = useSelector((state) => state.reactions);
  const creatives = useSelector((state) => state.content);
  const posts = useSelector((state) => state.posts);
  const userId = auth.user_metadata.uiuid
  const imgSrc = imagesBucketURL + userId + "/";

  const toast = useToast();
  const dispatch = useDispatch();

  const { addLike } = ReactionsApi;
  const { updatePost } = PostsApi;

  const colors = ['red', 'blue', 'green', 'teal', 'purple', 'pink', 'orange', 'cyan', 'gray', 'black', 'white'];
  const colorLabels = ['work', 'blog-post', 'creative', 'social', 'work', 'blog-post'];

  const realtimeWaitForReaction = async () => {
    let responseReceived = false;
    let response = null;

    const reactionChannel = supabase
      .channel('realtime reactions')
      .on('postgres_changes', {
        event: '*',
        schema: 'public',
        table: 'reactions'
      }, async (payload) => {
        const targetId = Number(payload.new.resource_id);
        const newReaction = payload.new.reaction;
        const creativeToUpdate = creatives.filter((obj) => obj.id === targetId);

        dispatch(updateContent({
          newContent: {
            ...creativeToUpdate[0],
            reaction: newReaction
          },
          id: targetId
        }))
      }).subscribe();

    while (!responseReceived) {
      await new Promise((resolve) => setTimeout(resolve, 100));
    }

    reactionChannel.unsubscribe();
    return response;
  }

  // const realtimeWaitForReactionToPost = async () => {
  //   let responseReceived = false;
  //   let response = null;

  //   const postReactionChannel = supabase
  //     .channel('realtime post reaction')
  //     .on('postgres_changes', {
  //       event: '*',
  //       schema: 'public',
  //       table: 'posts'
  //     }, async (payload) => {
  //       console.log(payload.new)
  //       const { id, reaction } = payload.new

  //     }).subscribe();

  //   while (!responseReceived) {
  //     await new Promise((resolve) => setTimeout(resolve, 100));
  //   }

  //   postReactionChannel.unsubscribe();
  //   return response;
  // }

  const scheduleItemView = (data) => {

    if (Object.keys(data.data).includes('body')) {
      return {
        ...data,
        remove: typeof data.data.body !== 'string',
        data: {
          output: typeof data.data.body === 'string' ? data.data.body : '',
          industry: data.data.industry,
          img: data.data.img
        }
      };
    }

    return {
      ...data,
      remove: typeof data.data.body !== 'string',
    };
  }

  const socialItemView = (data) => {
    return {
      ...data,
      remove: typeof data.data.body !== 'string',
      data: {
        output: typeof data.data.body === 'string' ? data.data.body : '',
        industry: data.data.industry,
      }
    };
  };

  const blogItemView = (data) => {
    return {
      ...data,
      remove: typeof data.data.body !== 'string',
      data: {
        output: typeof data.data.body === 'string' ? data.data.body : '',
        industry: data.data.industry,
      }
    };
  };

  const handleCopyClick = () => {
    toast({
      title: 'Copied to clipboard',
      status: 'info',
      duration: 3000,
      isClosable: true,
    })
  };

  const item = type === 'social' ? socialItemView(data) : type === 'blog' ? blogItemView(data) : scheduleItemView(data);

  const handleAddingLike = async (id, reaction, schedule_date) => {

    if (schedule_date) {
      const filteredPost = posts.filter(post => post.id === id)
      const postPayload = { ...filteredPost[0], reaction: reaction }

      await updatePost(postPayload, auth)
      setIsLoading(false)
      reduxUpdatePost(id, postPayload)
      // realtimeWaitForReactionToPost()
    } else {
      const filteredReaction = reactions.filter(reactionObject => Number(reactionObject.resource_id) === id);

      if (filteredReaction && filteredReaction?.length === 0) {
        const reactionId = uuidv4();
        const reactionPayload = {
          id: reactionId,
          resource: type,
          resource_id: id,
          reaction: reaction,
          "user_uiuid": userInfo.uiuid
        };

        await addLike(reactionPayload);
        setIsLoading(false)
        realtimeWaitForReaction()
      }
    }
  }

  const handleLikeClick = (id, schedule_date) => {
    setIsLoading(true)
    setIsLiked(true)
    handleAddingLike(id, 'like', schedule_date)
  }

  const postImg = (!item.data.img || (Array.isArray(item.data.img) && item.data.img.length === 0)) ? '' : (item.data.img[0][0] === 'h'?item.data.img[0] : imgSrc + item.data.img[0]);

  return (
    <Card  key={item.id}>
      {isLoading &&
        (<div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backdropFilter: 'blur(3px)'
        }}>
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
          />
        </div>)
      }
      <Stack
          direction={{
            base: 'column',
            md: 'row',
          }}
          spacing={{
            base: '3',
            md: '10',
          }}
          align="flex-start"
        >
          <Stack spacing="4">
            <Image 
              src={`${postImg ? postImg : `https://placehold.co/600x400/${colors[colorLabels.findIndex(c => c === item.type)]}/white?text=${data.type}`} `}
              name="Melinda Paul"
            />
            
          </Stack>
          <Box>
            <Stack
              spacing={{
                base: '1',
                md: '2',
              }}
              direction={{
                base: 'column',
                md: 'row',
              }}
            >
              <Text as="h2" fontWeight="bold" fontSize="xl">
                {item.type}
              </Text>
              <HStack
                fontSize={{
                  base: 'md',
                  md: 'lg',
                }}
              >
                <Badge colorScheme='green' variant='subtle'>{item.status}</Badge>
                
                {/* <Icon as={HiShieldCheck} color="green.500" /> */}
              </HStack>
            </Stack>
            <Text as="span" color={useColorModeValue('gray.500', 'gray.300')} lineHeight="1">
                  {item?.schedule_date}
                </Text>
            <Text mt="2">{item?.topic}</Text>
            <Box fontSize="sm" noOfLines={2}>
            {item.data.output?.length > 400 ? `${item.data.output.substring(0, 400)}...` : item.data.output}

            </Box>
            <Wrap shouldWrapChildren mt="5" color={useColorModeValue('gray.600', 'gray.300')}>
            {
            isLiked ? (
              <BiSolidHeart size={'1.5rem'} color="red" />
            ) : (
              <BiHeart
                size={'1.5rem'}
                onClick={() => handleLikeClick(item.id, item.schedule_date)}
                style={{ cursor: 'pointer' }}
              />
            )
          }
        <CopyToClipboard size={'1.5rem'} onCopy={handleCopyClick} text={item.data.output} style={{ cursor: 'pointer' }}>
          <GrCopy />
        </CopyToClipboard>
        <IconContentDrawer item={item} auth={auth} type={type} />
            </Wrap>
          </Box>
        </Stack>

      {/* <CardHeader>
        <Flex spacing='4'>
          <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap'>
            <Avatar name='Segun Adebayo' src={`https://placehold.co/600x400/${colors[colorLabels.findIndex(c => c === item.type)]}/white?text=${data.type}`} />
            <Spacer />
            <Text>{item.schedule_date}</Text>
            <Box>
              <Text size='sm'>Topic: {item.topic}</Text>
              {item.industry ? <Text>Industry: {item.industry}</Text> : ''}
              {postImg !== '' ? <></> : <Badge colorScheme='green' variant='subtle'>{item.status}</Badge>}
            </Box>
          </Flex>
        </Flex>
      </CardHeader>

      <CardBody>
        <Image src={postImg} />
        <Text>
          {item.data.output?.length > 400 ? `${item.data.output.substring(0, 400)}...` : item.data.output}
        </Text>
      </CardBody>

      <CardFooter
        justify='space-between'
        flexWrap='wrap'
        sx={{
          '& > button': {
            minW: '136px',
          },
        }}
      >
        <div style={{ display: 'flex' }}>
          {
            isLiked ? (
              <BiSolidHeart size={'1.5rem'} color="red" />
            ) : (
              <BiHeart
                size={'1.5rem'}
                onClick={() => handleLikeClick(item.id, item.schedule_date)}
                style={{ cursor: 'pointer' }}
              />
            )
          }
        </div>
        <CopyToClipboard size={'1.5rem'} onCopy={handleCopyClick} text={item.data.output} style={{ cursor: 'pointer' }}>
          <GrCopy />
        </CopyToClipboard>
        <IconContentDrawer item={item} auth={auth} type={type} />
      </CardFooter> */}
    </Card>
  )
}

export default ACGridItem;