const getAllPosts = async (auth) => {
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");
  reqHeaders.append("Authorization", `Bearer ${auth.session.access_token}`);

  const requestOptions = {
    method: 'GET',
    headers: reqHeaders
  }

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/posts`, requestOptions);
  return result.json();
}

const getPostById = async (payload) => {
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: 'GET',
    headers: reqHeaders
  }

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/todos/${payload.id}`, requestOptions);
  return result.json();

}

const addPost = async (payload) => {
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    "status": payload.status,
    "type": payload.type,
    "data": {
      "title": payload.data.title,
      "uiuid": payload.data.uiuid
    }
  });

  const requestOptions = {
    method: 'POST',
    headers: reqHeaders,
    body: raw
  }

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/todos`, requestOptions);
  return result.json();
}

const deletePostById = async (payload) => {
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: 'DELETE',
    headers: reqHeaders,
  }

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/todos/${payload.id}`, requestOptions);
  return result.json();
}

const updatePost = async (payload, auth) => {
  // thumbs up / down funcionality for work items
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");
  reqHeaders.append("Authorization", `Bearer ${auth.session.access_token}`);

  const raw = JSON.stringify(payload);

  const requestOptions = {
    method: 'PUT',
    headers: reqHeaders,
    body: raw,
    redirect: 'follow'
  };

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/posts/${payload.id}`, requestOptions);
  return result.json();
}

export default {
  getAllPosts,
  getPostById,
  addPost,
  updatePost,
  deletePostById
}