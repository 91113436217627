const getAllTodos = async () => {
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: 'GET',
    headers: reqHeaders
  }

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/todos`, requestOptions);
  return result.json();
}

const getTodoById = async (payload) => {
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: 'GET',
    headers: reqHeaders
  }

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/todos/${payload.id}`, requestOptions);
  return result.json();

}

const addTodo = async (payload) => {
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    "status": payload.status,
    "type": payload.type,
    "data": {
      "title": payload.data.title,
      "uiuid": payload.data.uiuid
    }
});

  const requestOptions = {
    method: 'POST',
    headers: reqHeaders,
    body: raw
  }

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/todos`, requestOptions);
  return result.json();
}

const deleteTodoById = async (payload) => {
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: 'DELETE',
    headers: reqHeaders,
  }

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/todos/${payload.id}`, requestOptions);
  return result.json();
}


const updateTodo = async (payload) => {
 // thumbs up / down funcionality for work items
}


// ******** WORK ITEMS *********

const addWorkItem = async (payload) => {
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    "status": payload.status,
    "type": payload.type,
    "data": {
      "uiuid": payload.data.uiuid,
      "name": payload.data.name,
      "description": payload.data.description,
      "skill": payload.data.skill,
      "tags": payload.data.tags,
      "level": payload.data.level
    }
  })

  const requestOptions = {
    method: 'POST',
    headers: reqHeaders,
    body: raw
  }

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/todos`, requestOptions);
  return result.json();
}



export default {
  getAllTodos,
  getTodoById,
  addTodo,
  deleteTodoById,
  addWorkItem
}