import { Box, BoxProps, Card, CardBody, CardFooter, Divider, Flex, Heading, useColorModeValue, Image, Stack, Text } from '@chakra-ui/react'
import screenImgAgents from '../../assets/screen-dashboard.jpg';

export const LandingDemoOverlay = (props) => {
  const { action, avatarProps, children, ...rest } = props

  return (
    <Box margin={'auto'}>
      <Flex justify="space-between">
        <Image
          src={screenImgAgents}
          alt='AI made simple for your small business'
          borderRadius='lg'
          zIndex={'998'}
        />
      </Flex>
    </Box>
  )
}
