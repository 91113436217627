import { Icon, Text, Flex, useColorModeValue } from '@chakra-ui/react'
import { HiMail, HiLink } from 'react-icons/hi'

export const UserInfo = ({ website, aeEmail }) => (
  <Flex
    w="100%"
    direction={{
      base: 'column',
      sm: 'row',
    }}
    mt="4"
    fontSize="sm"
    fontWeight="medium"
    color={useColorModeValue('blue.600', 'blue.300')}
  >
    <Flex align="flex-start">
      <Icon as={HiLink} mt="5px" />
      <Text ml="5px">{website}</Text>
    </Flex>
    <Flex align="flex-start" ml={{ base: '0px', sm: '20px'}}>
      <Icon as={HiMail} mt="5px" />
      <Text ml="5px">{aeEmail}</Text>
    </Flex>
  </Flex>
);
