import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Box } from '@chakra-ui/react';
import ImageGallery from './ImageGallery';

const MediaGallery = () => {

  const imagesBucketURL = `${process.env.REACT_APP_SUPABASE_PROJECT_URL}/storage/v1/object/public/users_files/`;

  const auth = useSelector((state) => state.auth);
  const gallery = useSelector((state) => state.gallery);
  const [imagesData, setImagesData] = useState([]);

  const userId = auth?.user_metadata.uiuid || '';

  const imageRenderer = (gallery) => {
    const readyImages = gallery.map((img) => ({
      src: `${imagesBucketURL + userId + "/" + img.name}`,
      width: 2,
      height: 2
    }))
    return readyImages;
  }

  useEffect(() => {
    if (gallery) {
      const updatedImages = imageRenderer(gallery);
      setImagesData(updatedImages);
    }
  }, [gallery]);

  return (
    <Box
      w='100%'
      h="100%"
      p={10}
    >
      <ImageGallery media={imagesData} />
    </Box>
  )
}

export default MediaGallery;