import { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    Icon,
    Menu,
    MenuButton,
    Text,
    IconButton,
    Box,
    Flex,
    Spacer,
    Heading,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    FormControl,
    InputGroup,
    Input,
    useToast,
    Progress,
} from '@chakra-ui/react'
import { v4 } from "uuid";
import { ChevronLeftIcon, HamburgerIcon } from '@chakra-ui/icons';
import { MdGroupWork } from 'react-icons/md';
import { Sidebar } from "../../components/ACSidebars";
import navigationData from "../../data/navigation";
import { addTopic as reduxAddTopic } from '../../features/topic/topicSlice';
import TopicsApi from '../../methods/TopicsApi';

import './index.css'

const { addTopic } = TopicsApi;

export const Topics = () => {
    const auth = useSelector((state) => state.auth);
    const topics = useSelector((state) => state.topics);
    const [creatingContent, setCreatingContent] = useState(false);
    const [closeSideBar, setCloseSideBar] = useState(false);
    const [value, setValue] = useState('');
    const toast = useToast();
    const dispatch = useDispatch();

    const handleChange = (event) => setValue(event.target.value);

    if (!auth || auth.authenticated === false) {
        return (<>
            <h1>Shucks, something went wrong!</h1>
            <pre>{JSON.stringify(auth, 2)}</pre>
        </>);
    }

    const showToastMessage = (toastStatus, title) => toast({
        position: 'top',
        title,
        status: toastStatus,
        duration: 5000,
        isClosable: true
    })

    const handleSubmit = async (event, auth, setCreatingContent, showToastMessage, value, setValue) => {
        setCreatingContent(true)
        const industry = auth.profile.industry.value

        const topicPayload = {
            value: value,
            uiuid: v4(),
            user_uiuid: auth.user_metadata.uiuid,
            author: 'Sandra Ae',
            data: { industry: industry }
        };

        const response = await addTopic(topicPayload, auth);
        if (response) {
            const created_at = new Date().toJSON();
            const reduxTopicPayload = { ...topicPayload, created_at }
            showToastMessage('info', 'Working on your new content, Boss! It may take up to 2 minutes to see this content on your dashboard.')
            dispatch(reduxAddTopic(reduxTopicPayload))
            setValue('')
            setCreatingContent(false)
        }
    }

    return (
        <>
            <Box position="sticky" top="0" zIndex="1" bg="white" p={4}>
                <Flex>
                    <Box><Icon as={MdGroupWork} w={8} h={8} color='black' /></Box>
                    <Spacer />
                    <Menu>
                        <MenuButton
                            as={IconButton}
                            aria-label="Options"
                            icon={closeSideBar ? <HamburgerIcon /> : <ChevronLeftIcon />}
                            // varia nt={"ghost"}
                            onClick={() => setCloseSideBar(closeSideBar ? false : true)}
                        />
                    </Menu>
                </Flex>
            </Box>
            <Flex>
                <Box position="sticky" top="0" height="calc(100vh - 4rem)" width={'max-content'}
                    display={closeSideBar ? 'none' : 'block'} >
                    <Sidebar navigationData={navigationData} />
                </Box>

                <Box className="responsive-box" width={closeSideBar ? '100%' : '80%'} height="full" m={5}>
                    <Heading>Topics</Heading>
                    <FormControl>
                        <InputGroup>
                            <Input
                                className="responsive-input"
                                onChange={handleChange}
                                value={value}
                                mt={2}
                                width="100%"
                                size="lg"
                                variant="outline"
                                placeholder="Give Sandra a new topic to create content on"
                                onKeyDown={e => e.key === 'Enter'
                                    ? handleSubmit(e, auth, setCreatingContent, showToastMessage, value, setValue)
                                    : null}
                            />
                        </InputGroup>
                    </FormControl>
                    {creatingContent
                        ? <div style={{ textAlign: 'center' }} ><Progress isIndeterminate className='responsive-loader' style={{ marginTop: '14px' }} /><br />Creating content...</div>
                        : ''}
                    <Box
                        as="section"
                        bg="bg.surface"
                        py={{
                            base: '4',
                            md: '8',
                        }}
                    >
                        <Text>This is a list of all of your previous topics entered for Sandra to work on.</Text>

                        <TableContainer>
                            <Table variant='simple' size={{ base: 'sm', md: 'lg' }}>
                                <TableCaption>These are ordered begining with the most recent topic</TableCaption>
                                <Thead>
                                    <Tr>
                                        <Th>Date</Th>
                                        <Th>Topics</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {topics.map((t, i) =>
                                        <Tr key={i}>
                                            <Td>{new Date(t.created_at).toLocaleDateString()}</Td>
                                            <Td>{t.value}</Td>
                                        </Tr>
                                    )}
                                </Tbody>
                                <Tfoot>

                                </Tfoot>
                            </Table>
                        </TableContainer>

                    </Box>
                </Box>
            </Flex>
        </>
    )
}
