import { createSlice } from '@reduxjs/toolkit';

export const topicSlice = createSlice({
    name: 'topics',
    initialState: [],
    reducers: {
        setTopics: (state, action) => {
            const postsArray = action.payload.data;
            state = [...postsArray];
            return state;
        },
        addTopic: (state, action) => {
            const newTopic = action.payload;
            return [newTopic, ...state];
        },
    },
})

// this is for dispatch
export const { addTopic, setTopics } = topicSlice.actions;

// this is for configureStore
export default topicSlice.reducer;
