import { useSelector, useDispatch } from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Link,
  Stack,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import {
  FiArrowUpRight,
  FiHome,
  FiX
} from 'react-icons/fi'
import { FaRobot } from 'react-icons/fa';
import { isEmpty } from "lodash";
import Gateway from '../../methods/Gateway';
import { ColumnHeader, ColumnIconButton } from '../Columns/Column';
import DragDrop from './DragDrop';
import { clearAuth } from '../../features/auth/authSlice';

const { signOut } = Gateway;

const NavButton = (props) => (
  <Button
    width="full"
    borderRadius="0"
    variant="ghost"
    size="lg"
    fontSize="sm"
    _hover={{
      bg: mode('gray.100', 'gray.700'),
    }}
    _active={{
      bg: mode('gray.200', 'gray.600'),
    }}
    _focus={{
      boxShadow: 'none',
    }}
    _focusVisible={{
      boxShadow: 'outline',
    }}
    {...props}
  />
)
export const NavLink = (props) => {
  const { icon, ...linkProps } = props
  return (
    <Link
      px="2"
      py="1.5"
      borderRadius="md"
      _hover={{
        bg: mode('gray.100', 'gray.700'),
      }}
      _activeLink={{
        bg: 'gray.700',
        color: 'white',
      }}
      {...linkProps}
    >
      <HStack justify="space-between">
        <HStack spacing="3">
          <Icon as={icon} />
          <Text as="span" fontSize="sm" lineHeight="1.25rem">
            {props.children}
          </Text>
        </HStack>
        {props.isExternal && (
          <Icon as={FiArrowUpRight} boxSize="4" color={mode('gray.600', 'gray.400')} />
        )}
      </HStack>
    </Link>
  )
}
export const NavHeading = (props) => (
  <Text
    as="h4"
    fontSize="xs"
    fontWeight="semibold"
    px="2"
    lineHeight="1.25"
    color={mode('gray.600', 'gray.400')}
    {...props}
  />
);

export const Navbar = (props) => {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const { industry } = props;

  const handleSignout = async () => {
    const userSignOut = await signOut()
    window.Intercom('shutdown');
    if (userSignOut.statusCode === 201) {
      dispatch(clearAuth());
      localStorage.removeItem('ac_batptl')
      navigate('/')
    }
  }

  return (
    <Flex as="nav" height="full" direction="column" justify="space-between" {...props}>
      <Stack spacing="3">
        <ColumnHeader>
          <HStack spacing="3">
            <ColumnIconButton
              onClick={props.onClose}
              aria-label="Close navigation"
              icon={<FiX />}
              display={{
                base: 'inline-flex',
                lg: 'none',
              }}
            />
            <Flex direction={'column'}>
              <Text fontWeight="bold" fontSize="sm" lineHeight="1.25rem">
                Hello, {auth.user_metadata.name}
              </Text>
            </Flex>

          </HStack>
        </ColumnHeader>

        <Stack px="3" spacing="6">
          <Box w="100%" textAlign="center">
            {!isEmpty(industry) && <Text border="1px dashed black " py="5px">{industry}</Text>}
          </Box>
          <Stack spacing="1">
            <NavLink icon={FiHome} aria-current="page">Home</NavLink>
            <NavLink icon={FaRobot}>Team</NavLink>
          </Stack>
          <Box width="100%" maxW="100%">
            <DragDrop />
          </Box>
        </Stack>
        <Stack>
          <Box borderTopWidth="1px">
            <NavButton onClick={handleSignout}>Sign Out</NavButton>
          </Box>
        </Stack>
      </Stack>

    </Flex>
  )
}
