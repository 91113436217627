import { createSlice } from '@reduxjs/toolkit';

export const blogSlice = createSlice({
    name: 'blogs',
    initialState: [],
    reducers: {
        setBlogs: (state, action) => {
            const blogsArray = action.payload.data;
            state = [...blogsArray];
            return state;
          },
        addPost: (state, action) => {

            state.push(action.payload);
        },
    },
})

// this is for dispatch
export const { addBlog, setBlogs } = blogSlice.actions;
 
// this is for configureStore
export default blogSlice.reducer;
          