import { Box, Center, Flex, Heading, Stack, VStack } from '@chakra-ui/react'
import { SignUpForm } from '../../components/Onboarding/SignupForm'

export const Signup = (props) => {
  const { mixpanel } = props;

  return (
    <Box
      py={{
        base: '12',
        md: '24',
      }}
      maxW="7xl"
      mx="auto"
    >
      <Stack direction="row" spacing="12">
        <Flex flex="1">
          <SignUpForm mixpanel={mixpanel} />
        </Flex>
        <Center
          flex="1"
          px={{
            lg: '8',
          }}
          display={{
            base: 'none',
            lg: 'flex',
          }}
        >
          <VStack spacing="8">
            <Stack direction="row" spacing="1">
              <Heading size="sm" fontWeight="medium" textAlign="center">
                Create, train, and deploy Artificial Employees
              </Heading>
            </Stack>
          </VStack>
        </Center>
      </Stack>
    </Box>
  )
}
