import { createSlice } from '@reduxjs/toolkit';

export const skillSlice = createSlice({
    name: 'skills',
    initialState: [],
    reducers: {
        addSkill: (state, action) => {
            const skill = {
                id: Date.now(),
                text: action.payload,
            };

            state.push(skill);
        },
    },
})

// this is for dispatch
export const { addSkill } = skillSlice.actions;

// this is for configureStore
export default skillSlice.reducer;
