import { v4 } from "uuid";

const getAllTweets = async (auth) => {
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");
  reqHeaders.append("Authorization", `Bearer ${auth.session.access_token}`);

  const requestOptions = {
    method: 'GET',
    headers: reqHeaders
  }

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/tweets`, requestOptions);
  return result.json();
}

const getTweetById = async (payload) => {
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: 'GET',
    headers: reqHeaders
  }

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/todos/${payload.id}`, requestOptions);
  return result.json();

}

const addTweet = async (payload, auth) => {
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");
  reqHeaders.append("Authorization", `Bearer ${auth.session.access_token}`);

  const raw = JSON.stringify({
    "user_uiuid": auth.user_metadata.uiuid,
    "uiuid": v4(),
    "status": "new",
    "payload": payload
  });

  const requestOptions = {
    method: 'POST',
    headers: reqHeaders,
    body: raw
  }

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/tweets`, requestOptions);
  return result.json();
}

const deleteTweetById = async (payload) => {
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: 'DELETE',
    headers: reqHeaders,
  }

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/todos/${payload.id}`, requestOptions);
  return result.json();
}


const updateTweet = async (payload) => {
  // thumbs up / down funcionality for work items
}




export default {
  getAllTweets,
  getTweetById,
  addTweet,
  deleteTweetById
}