import React, { useState, useEffect, useCallback } from 'react';
import { isEmpty } from 'lodash';
import Gallery from 'react-photo-gallery';
import ImageGalleryEmptyState from './ImageGalleryEmptyState';
import SelectedImage from './SelectedImage';

const ScheduledImageGallery = ({ media, handleAddImgToState }) => {
    const [imageList, setImageList] = useState([]);

    useEffect(() => {
        if (media) setImageList(media)
    }, [media]);

    const imageRenderer = useCallback(
        ({ index, left, top, key, photo }) => (
            <SelectedImage
                key={key}
                margin={"2px"}
                index={index}
                photo={photo}
                left={left}
                top={top}
                handleAddImgToState={handleAddImgToState}
            />
        ),
        []
    );

    if (isEmpty(imageList)) return <ImageGalleryEmptyState />

    return <Gallery photos={imageList} renderImage={imageRenderer} />
}

export default ScheduledImageGallery;