import * as React from 'react';

function PricingPage() {
  // Paste the stripe-pricing-table snippet in your React component
  return (
    <stripe-pricing-table
      pricing-table-id={process.env.REACT_APP_STRIPE_PK_ID}
      publishable-key={process.env.REACT_APP_STRIPE_PK}
    >
    </stripe-pricing-table>
  );
}

export default PricingPage;