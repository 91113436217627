import React, { useState } from 'react';
import { useSelector } from "react-redux";
import {
  Container,
  HStack,
  Text,
  Tab,
  TabList,
  Tabs,
  Box,
  TabPanels,
  TabPanel,
  Heading,
  Button,
  Flex,
  Tooltip
} from '@chakra-ui/react';
import { RepeatIcon } from '@chakra-ui/icons';
import { FaBlog, FaPencilAlt, FaUsers, FaComments, FaCalendarAlt } from "react-icons/fa";

const TableTabs = ({ setListView }) => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabsChange = (index) => {
    setTabIndex(index)
  }

  return (
    <Box className='work-table-shepherd'>
      <Box as="section" pb={{ base: '2', md: '2' }}>
        <Box borderBottomWidth="1px" bg="bg.surface">
          <Container pt="3">
          <Heading size="md" color="fg.emphasized" fontWeight="medium">Inbox</Heading>
            <HStack spacing={{ base: '3', md: '4' }} justify={{ base: 'start', md: 'space-between' }}>
                <Text color="fg.emphasized" fontWeight="medium">
                  🤖⚠️ Early Beta: Since content is generated by AI, you might see some surprises, including inaccurate information. Make sure to check the facts and share feedback so we can learn and improve.
                </Text>
            </HStack>
          </Container>
        </Box>
      </Box>
      <Tabs index={tabIndex} onChange={handleTabsChange}>
        {/* Console error on <button> wrapped in <button> is coming from code below  */}
        <TabList>
          <Tab width={'50%'}>
            <Tooltip hasArrow label="Content">
              <Button onClick={() => setListView('creative')} leftIcon={<FaPencilAlt />} colorScheme='black' size='lg' width={'100%'}>Ready for Review</Button>
            </Tooltip>
          </Tab>
          <Tab width={'50%'}>
            <Tooltip hasArrow label="Schedule">
              <Button onClick={() => setListView('schedule')} leftIcon={<FaCalendarAlt />} colorScheme='black' size='lg' width={'100%'}>Schedule</Button>
            </Tooltip>
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Flex direction="column">
              <Flex>
                <Heading size={'sm'}>
                  Drafts
                </Heading>
                <Button
                  leftIcon={<RepeatIcon />}
                  ml={5}
                  colorScheme="blue"
                  variant="link"
                  onClick={() => window.location.reload()}
                >
                  Refresh
                </Button>
              </Flex>
              <p>AI Generated Content</p>
            </Flex>
          </TabPanel>

          <TabPanel>
            <Flex>
              <Heading size={'sm'}>
                Schedule
              </Heading>
              <Button
                leftIcon={<RepeatIcon />}
                ml={5}
                colorScheme="blue"
                variant="link"
                onClick={() => window.location.reload()}
              >
                Refresh
              </Button>
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}
export default TableTabs;