import {
  HStack, Logo, Icon, Menu, MenuButton, SimpleGrid,
  IconButton, Input, VStack, StackDivider, AbsoluteCenter, Avatar, InputGroup, InputLeftElement, Box, Flex, Button, Spacer, Switch, Heading, ButtonGroup, Container
} from '@chakra-ui/react'
import { useEffect, useState } from 'react';
import AddSkill from '../../components/Skills/addSkill';
import { Navbar } from '../../components/Navs/Navbar';
import { TopNavbar } from "../../components/Navs/TopNavbar";
import SocialAccounts from '../../components/ACTools/SocialTools';
import { Sidebar } from "../../components/ACSidebars";
import navigationData from "../../data/navigation";
import { ChevronLeftIcon, HamburgerIcon } from '@chakra-ui/icons';
import { MdMenu, MdOutlineGroup, MdGroupWork } from 'react-icons/md';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';



export const Settings = () => {
  const [closeSideBar, setCloseSideBar] = useState(false);
  const skills = useSelector((state) => state.skills);
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate()

  if (!auth || auth.authenticated === false) {
    return (<>
      <h1>Shucks, something went wrong!</h1>
      <pre>{JSON.stringify(auth, 2)}</pre>
    </>);
  }
  const handleClearStorage = () => {
    localStorage.clear();
    window.location.href = '.'
  };

  /*
  localStorage.removeItem('oauthRequestTokenSecret');
      localStorage.removeItem('oauthRequestToken');
  */

  const handleClearTokens = () => {
    localStorage.removeItem('oauthRequestTokenSecret');
    localStorage.removeItem('oauthRequestToken');
    navigate('/dashboard');
  };





  return (
    <>
      <Box position="sticky" top="0" zIndex="1" bg="white" p={4}>
        <Flex>
          <Box><Icon as={MdGroupWork} w={8} h={8} color='black' /></Box>
          <Spacer />
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={closeSideBar ? <HamburgerIcon /> : <ChevronLeftIcon />}
              // varia nt={"ghost"}
              onClick={() => setCloseSideBar(closeSideBar ? false : true)}
            />
            {/* You can add MenuList and MenuItems here for dropdown options */}
          </Menu>
        </Flex>
      </Box>
      <Flex>
        <Box
          width="20%"
          display={closeSideBar ? 'none' : 'block'}
          position="sticky" height="100vh" overflowY="auto"
        >
          <Sidebar navigationData={navigationData} />
        </Box>
        <Box width={closeSideBar ? '100%' : '80%'} height="full">
          <Heading>Settings</Heading>
          <SimpleGrid columns={[2, null, 3]} spacing='40px' my={50}>
            <Box height='80px'>
              <Button colorScheme={'red'} onClick={handleClearStorage}>Hard Reset</Button>
            </Box>
            <Box height='80px'>
              <Button colorScheme={'black'} onClick={handleClearStorage}  >Clear Tokens</Button>
            </Box>
          </SimpleGrid>
        </Box>
      </Flex>
    </>
  )
}
