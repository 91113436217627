import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { addAuth } from '../../features/auth/authSlice';
import { Button, Divider, FormControl, FormHelperText, FormLabel, HStack, Input, Stack, Text, Switch, useToast } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { OAuthButtonGroup } from "../Onboarding/OAuthButtonGroup";
import Gateway from "../../methods/Gateway";
const { signUp } = Gateway;

function ApiResponse(data) {
    const newObj = {};
    newObj.email = data[2].value;
    newObj.password = data[3].value;
    newObj.user_metadata = {
        uiuid: uuidv4(),
        organization: data[0].value,
        org_uiuid: uuidv4(),
        name: data[1].value,
        timestamp: Date.now()
    }
    return newObj;
}

export default function AddAuth({ auth }) {
    const [apiResponse, setApiResponse] = useState(auth);
    const [testimonial, setTestimonial] = useState(false);
    const toast = useToast();
    // initial the dispatch here
    const dispatch = useDispatch();

    const showToastMessage = (toastStatus, title) => toast({
        position: 'top',
        title,
        status: toastStatus,
        duration: 4000,
        isClosable: true
    })

    const toggleTestimonial = () => {
        setTestimonial(!testimonial)
    }

    const addAuthHandler = async (event) => {
        event.preventDefault();

        const formData = Array.from(event.target).map((input) => ({ key: input.name, value: input.value }));
        // update the state here using addTodo action
        // action only receive one parameter, which is payload

        const response = ApiResponse(formData);
        const result = await signUp(response)

        if (result.statusCode === 201 && result.data.user != null && result.data.session != null) {
            result.authenticated = true;
            dispatch(addAuth(result));
        } else {
            showToastMessage('error', 'Looks like you’ve already created an account! Please try signing in.')
        }


        // const {data, authenticated} = result;
        // const {id, email, user_metadata} = data.user;
        // const {access_token} = data.session;
        // const auth = {
        //     id,
        //     email,
        //     user_metadata,
        //     authenticated,
        //     access_token
        // };

        // setApiResponse(auth);
    };

    return (
        <form onSubmit={addAuthHandler}>
            <Stack spacing="5">
                <FormControl isRequired>
                    <FormLabel htmlFor="name">Company</FormLabel>
                    <Input id="organization" name="organization" type="text" />
                </FormControl>
                <FormControl isRequired>
                    <FormLabel htmlFor="name">Name</FormLabel>
                    <Input id="name" name="name" type="text" />
                </FormControl>
                <FormControl isRequired>
                    <FormLabel htmlFor="email">Email</FormLabel>
                    <Input id="email" name="email" type="email" />
                </FormControl>
                <FormControl isRequired>
                    <FormLabel htmlFor="password">Password</FormLabel>
                    <Input id="password" name="password" type="password" />
                    <FormHelperText color="muted">At least 8 characters long</FormHelperText>
                </FormControl>
                <FormControl>
                    <FormLabel htmlFor="testimonial">Are you willing to give a testimonial?</FormLabel>
                    <div style={{ display: 'flex' }}>
                        <Switch onChange={toggleTestimonial} id="testimonial" name="testimonial" size='lg' value={testimonial} />
                        <p style={{ marginLeft: '15px' }}>{testimonial ? 'Yes' : 'No'}</p>
                    </div>
                    <br />
                </FormControl>
            </Stack>
            <Stack spacing="6">
                <Button variant="primary" type={'submit'}>
                    Create Account
                </Button>
                <HStack>
                    <Divider />
                    <Text fontSize="sm" whiteSpace="nowrap" color="muted">
                        By signing up, you agree to our <Link to="https://www.artificialcompute.com/privacy-policy-0">Terms of Use</Link>, <Link to="https://www.artificialcompute.com/privacy-policy">Privacy Policy</Link><br /> and <Link to="https://www.artificialcompute.com/platform-guidelines">Platform Guidelines</Link>
                    </Text>
                    <Divider />
                </HStack>
                {/* <OAuthButtonGroup /> */}
            </Stack>
        </form>
    );
}
