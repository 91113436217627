import { theme as proTheme } from '@chakra-ui/pro-theme'
import { extendTheme, theme as baseTheme } from '@chakra-ui/react'

export const theme = extendTheme(
  {
    styles: {
      global: {
        'body::-webkit-scrollbar': {
          display: 'none',
        },
        'body': {
          scrollbarWidth: 'none', // Firefox
          msOverflowStyle: 'none', // IE 10+
        },
      },
    },
    colors: {
      brand: baseTheme.colors.teal, dark: baseTheme.colors.gray[900], black: {
        50: '#c9d6d9',
        100: '#060606FF',
        200: '#060606FF',
        300: '#060606FF',
        400: '#060606FF',
        500: '#060606FF',
        600: '#060606FF',
        700: '#060606FF',
        800: '#060606FF',
        900: '#060606FF'
      },
    },
  },
  proTheme,
)
