import { createSlice } from '@reduxjs/toolkit';

export const postSlice = createSlice({
  name: 'posts',
  initialState: [],
  reducers: {
    setPosts: (state, action) => {
      const postsArray = action.payload.data;
      state = [...postsArray];
      return state;
    },
    addPost: (state, action) => {
      const newPost = action.payload;
      return [...state, newPost];
    },
    updatePost: (state, action) => {
      const { id, newPost } = action.payload;

      const index = state.findIndex(content => content.id === id);
      if (index !== -1) {
        state[index] = newPost;
      }
    },
  },
})

// this is for dispatch
export const { addPost, setPosts, updatePost } = postSlice.actions;

// this is for configureStore
export default postSlice.reducer;
