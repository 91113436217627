import { createSlice } from '@reduxjs/toolkit';

export const reactionSlice = createSlice({
    name: 'reactions',
    initialState: [],
    reducers: {
        setReactions: (state, action) => {
            const reactionsArray = action.payload.data;
            state = [...reactionsArray];
            return state;
        },
        addReaction: (state, action) => {
            state.push(action.payload.data);
            return state;
        },
        updateReaction: (state, action) => {
            const { id, reaction } = action.payload.data;
            const index = state.findIndex(r => r.id === id);
            if (index !== -1) {
                state[index] = reaction;
                return state;
            }
        },
    },
})

// this is for dispatch
export const { addReaction, setReactions, updateReaction } = reactionSlice.actions;

// this is for configureStore
export default reactionSlice.reducer;