import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Flex,
  Button,
  Stack,
  Text,
  Badge,
  Divider,
  Modal,
  ModalContent,
  ModalBody,
  ModalOverlay,
  ModalCloseButton,
  ModalHeader,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  useToast,
  Spinner,
} from '@chakra-ui/react'
import { FaTwitter, FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa';
import Threads from '../../Logos/Threads';
import LocalStorage from '../../../methods/LocalStorage';
import Gateway from '../../../methods/Gateway';
import ToolsApi from '../../../methods/ToolsApi';
import { SocialProviders } from '../../../config';
import { useNavigate, Link } from "react-router-dom";

const { startTwitterAuth } = Gateway;
const { addTool, Tool } = ToolsApi

const SocialMediaLinkButtons = ({ toggleShowDashboard }) => {
  const [loading, setLoading] = useState(false);
  const [modalAccount, setModalAccount] = useState({});
  const navigate = useNavigate();

  const toast = useToast();
  const auth = useSelector((state) => state.auth);
  const { saveState, loadState } = LocalStorage;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const twitterLinked = loadState('twitterLinked') || false;
  const fbLinked = loadState('fbLinked') || false;
  const igLinked = loadState('igLinked') || false;
  const ytLinked = loadState('ytLinked') || false;
  const liLinked = loadState('liLinked') || false;
  const authPayload = loadState('ac_batptl');

  const showToastMessage = (toastStatus, title) => toast({
    position: 'top',
    title,
    status: toastStatus,
    duration: 5000,
    isClosable: true
  })

  const startTwitterAuthRequest = async () => {
    setLoading(true);

    const response = await startTwitterAuth(auth);
    if(response.redirectUrl) {
      const { redirectUrl, oauthRequestToken, oauthRequestTokenSecret } = response;
      if (redirectUrl) {
        saveState('oauthRequestToken', oauthRequestToken)
        saveState('oauthRequestTokenSecret', oauthRequestTokenSecret)
        window.location.href = redirectUrl;
      }
    }
  }

  const handleSocialAccountCredentials = async (event) => {
    event.preventDefault();
    setLoading(true)

    const formData = Array.from(event.target).map((input) => (
      { key: input.name, value: input.value }
    ));
    const socialUser = formData[0].value;

    const toolPayload = new Tool({
      toolUser: `${modalAccount.name}User`,
      toolAccess: {
        username: socialUser
      },
      type: modalAccount.name,
      status: 'read-only'
    }, authPayload.data);

    const toolResponse = await addTool(toolPayload, authPayload.data);
    if (toolResponse.statusCode === 201) {
      setLoading(false)
      onClose();
      switch (modalAccount.name) {
        case 'facebook':
          saveState('fbLinked', true);
          break;
        case 'instagram':
          saveState('igLinked', true);
          break;
        case 'youtube':
          saveState('ytLinked', true);
          break;
        case 'linkedIn':
          saveState('liLinked', true);
          break;
        default:
          return;
      }
      showToastMessage('success', 'Your data have been stored successfully!')
    } else {
      setLoading(false)
      onClose();
      showToastMessage('error', 'There was a problem with the request. Please try again')
    }
  }


  const handleAccountConnect = (network) => {
    const socialNetwork = SocialProviders.find(item => item.name === network);
    setModalAccount(socialNetwork);
    onOpen();
  }


  return (
    <>
      <Box w="100%" mt="5%" minH="400px">
        <Flex direction="column" gap="1">

          <Box w="100%" h="60px" background="gray.100" borderTopRadius="10">
            <Flex w="100%" h="100%">
              <Box w="8%" h="100%" mx={2}>
                <Flex justify="center" align="center" w="100%" h="100%">
                  <FaTwitter  size={25} color="#00acee" />
                </Flex>
              </Box>
              <Divider orientation="vertical" />
              <Box w="98%" h="100%" pr="2%">
                <Flex w="100%" h="100%" align="center" justify="space-between">
                  <Box w="85%" pl="1%">
                    <Flex align="center" justify="flex-start" w="100%">
                      <Box w="30%">
                        <Text fontSize="1rem" ml="2%" color="gray.500">Twitter                         <Badge ml="5%" fontSize="sm" background="gray.400" color="white" borderRadius="0px" variant="solid">Realtime</Badge>
</Text>
                      </Box>
                    </Flex>
                  </Box>
                  <Box h="100%" w="15%">
                    <Flex w="100%" h="100%" align="center" justify="flex-end">
                      {
                        twitterLinked
                          ? <Text fontSize="sm" color="blue.600">Account Linked</Text>
                          : <Button size="sm" colorScheme="blue" onClick={startTwitterAuthRequest}>Connect</Button>
                      }
                    </Flex>
                  </Box>
                </Flex>
              </Box>
            </Flex>
          </Box>

          <Box w="100%" h="60px" background="gray.100">
            <Flex w="100%" h="100%">
              <Box w="8%" h="100%"  mx={2}>
                <Flex justify="center" align="center" w="100%" h="100%">
                  <FaInstagram size={25} color="#833AB4" />
                </Flex>
              </Box>
              <Divider orientation="vertical" />
              <Box w="98%" h="100%" pr="2%">
                <Flex w="100%" h="100%" align="center" justify="space-between">
                  <Box w="90%" pl="1%">
                    <Flex align="center" justify="flex-start" w="100%">
                      <Box w="30%">
                        <Text fontSize="1rem" ml="2%" color="gray.500">Instagram                         <Badge ml="5%" fontSize="sm" background="gray.400" color="white" borderRadius="0px" variant="solid">Scheduled</Badge>
</Text>
                      </Box>
                    </Flex>
                  </Box>
                  <Box h="100%" w="25%">
                    <Flex w="100%" h="100%" align="center" justify="flex-end">
                      {
                        igLinked
                          ? <Text fontSize="sm" color="blue.600">Account Linked</Text>
                          : <Button size="sm"  colorScheme="blue" onClick={() => handleAccountConnect('instagram')}> Connect </Button>
                      }
                    </Flex>
                  </Box>
                </Flex>
              </Box>
            </Flex>
          </Box>

          <Box w="100%" h="60px" background="gray.100">
            <Flex w="100%" h="100%">
              <Box w="8%" h="100%"  mx={2}>
                <Flex justify="center" align="center" w="100%" h="100%">
                  <FaFacebook size={25} color="#3b5998" />
                </Flex>
              </Box>
              <Divider orientation="vertical" />
              <Box w="98%" h="100%" pr="2%">
                <Flex w="100%" h="100%" align="center" justify="space-between">
                  <Box w="85%" pl="1%">
                    <Flex align="center" justify="flex-start" w="100%">
                      <Box w="30%">
                        <Text fontSize="1rem" ml="2%" color="gray.500">Facebook                         <Badge ml="5%" fontSize="sm" background="gray.400" color="white" borderRadius="0px" variant="solid">Scheduled</Badge>
</Text>
                      </Box>
                    </Flex>
                  </Box>
                  <Box h="100%" w="25%">
                    <Flex w="100%" h="100%" align="center" justify="flex-end">
                      {
                        fbLinked
                          ? <Text fontSize="sm" color="blue.600">Account Linked</Text>
                          : <Button size="sm" colorScheme="blue" onClick={() => handleAccountConnect('facebook')}> Connect </Button>
                      }
                    </Flex>
                  </Box>
                </Flex>
              </Box>
            </Flex>
          </Box>

          <Box w="100%" h="60px" background="gray.100">
            <Flex w="100%" h="100%">
              <Box w="8%" h="100%"  mx={2}>
                <Flex justify="center" align="center" w="100%" h="100%">
                  <FaYoutube size={25} color="#c4302b" />
                </Flex>
              </Box>
              <Divider orientation="vertical" />
              <Box w="98%" h="100%" pr="2%">
                <Flex w="100%" h="100%" align="center" justify="space-between">
                  <Box w="85%" pl="1%">
                    <Flex align="center" justify="flex-start" w="100%">
                      <Box w="25%">
                        <Text fontSize="1rem" ml="2%" color="gray.500">Youtube                         
                        <Badge ml="5%" fontSize="sm" background="gray.400" color="white" borderRadius="0px" variant="solid">Scheduled</Badge>
</Text>
                      </Box>
                    </Flex>
                  </Box>
                  <Box h="100%" w="25%">
                    <Flex w="100%" h="100%" align="center" justify="flex-end">
                      {
                        ytLinked
                          ? <Text fontSize="sm" color="blue.600">Account Linked</Text>
                          : <Button size="sm" colorScheme="blue" onClick={() => handleAccountConnect('youtube')}> Connect </Button>
                      }
                    </Flex>
                  </Box>
                </Flex>
              </Box>
            </Flex>
          </Box>


          <Box w="100%" h="60px" background="gray.100" borderBottomRadius="10">
            <Flex w="100%" h="100%">
              <Box w="8%" h="100%"  mx={2}>
                <Flex justify="center" align="center" w="100%" h="100%">
                  <FaLinkedin size={25} color="#0072b1" />
                </Flex>
              </Box>
              <Divider orientation="vertical" />
              <Box w="92%" h="100%" pr="2%">
                <Flex w="100%" h="100%" align="center" justify="space-between">
                  <Box w="85%" pl="1%">
                    <Flex align="center" justify="flex-start" w="100%">
                      <Box w="25%">
                        <Text fontSize="1rem" ml="2%" color="gray.500">LinkedIn <Badge ml="5%" fontSize="sm" background="gray.400" color="white" borderRadius="0px" variant="solid">Scheduled</Badge></Text>
                      </Box>
                    </Flex>
                  </Box>
                  <Box h="100%" w="25%">
                    <Flex w="100%" h="100%" align="center" justify="flex-end">
                      {
                        liLinked
                          ? <Text fontSize="sm" color="blue.600">Account Linked</Text>
                          : <Button size="sm" colorScheme="blue" onClick={() => handleAccountConnect('linkedIn')}>Connect</Button>
                      }
                    </Flex>
                  </Box>
                </Flex>
              </Box>
            </Flex>
          </Box>

          <Box w="100%" h="60px" background="gray.100" borderBottomRadius="10">
            <Flex w="100%" h="100%">
              <Box w="8%" h="100%"  mx={2}>
                <Flex justify="center" align="center" w="100%" h="100%">
                  <Threads />
                </Flex>
              </Box>
              <Divider orientation="vertical" />
              <Box w="92%" h="100%" pr="2%">
                <Flex w="100%" h="100%" align="center" justify="space-between">
                  <Box w="85%" pl="1%">
                    <Flex align="center" justify="flex-start" w="100%">
                      <Box w="25%">
                        <Text fontSize="lg" ml="2%" color="gray.500">Threads Account</Text>
                      </Box>
                      <Box>
                        <Badge ml="5%" fontSize="sm" background="gray.300" color="white" borderRadius="0px" variant="solid">Coming Soon</Badge>
                      </Box>
                    </Flex>
                  </Box>
                  <Box h="100%" w="15%">
                    <Flex w="100%" h="100%" align="center" justify="flex-end">
                      {/* <Button fontSize="xs" colorScheme="blue" isDisabled>Connect</Button> */}
                      {/* <Text fontSize="sm" color="blue.600">Account Linked</Text> */}
                    </Flex>
                  </Box>
                </Flex>
              </Box>
            </Flex>
          </Box>

          <Box w="100%" h="100px" mt="3%">
            <Flex justify="flex-end" align="center" w="100%">
              <Button colorScheme="blue" onClick={() => navigate('/dashboard')}>I'm Done Setting up Social Media</Button>
            </Flex>
          </Box>

        </Flex>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text fontWeight="bold" fontSize="xl" textAlign="center" py="10px">Link Account</Text>
            <Divider />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box w="100%" h="100%" pb="10%">
              <Flex align="center" w="100%" h="100%">
                <Box w="15%">
                  {modalAccount.faIcon}
                </Box>
                <Box w="85%">
                  <Flex direction="column" w="100%">
                    <Text fontSize="xl" casing="capitalize">{modalAccount.name}</Text>
                    <Text fontSize="xs">Enter your handle to connect your account</Text>
                  </Flex>
                </Box>
              </Flex>
            </Box>
            {
              loading ? (
                <Flex w="100%" h="100%" justify="center" align="center" minH="150px">
                  <Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='blue.500'
                    size='xl'
                  />
                </Flex>
              ) : (
                <form onSubmit={handleSocialAccountCredentials}>
                  <Stack spacing="5">
                    <FormControl isRequired>
                      <FormLabel htmlFor="name">Username</FormLabel>
                      <Input id="username" name="username" type="text" />
                    </FormControl>
                  </Stack>
                  <Stack mt="10" pb="5">
                    <Button colorScheme={modalAccount.colorScheme} type="submit">
                      Submit
                    </Button>
                  </Stack>
                </form>
              )
            }
          </ModalBody>
        </ModalContent>
      </Modal>

    </>
  )

}

export default SocialMediaLinkButtons;



