import { createSlice } from '@reduxjs/toolkit';

export const workItemSlice = createSlice({
    name: 'workItems',
    initialState: [],
    reducers: {
        addWorkItem: (state, action) => {
            const workItem = {
                id: Date.now(),
                text: action.payload,
            };

            state.push(workItem);
        },
    },
})

// this is for dispatch
export const { addWorkItem } = workItemSlice.actions;
 
// this is for configureStore
export default workItemSlice.reducer;
          