import { Box, Flex } from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { useSelector } from "react-redux";
import { Navbar } from '../../components/Navs/Navbar';
import { TopNavbar } from "../../components/Navs/TopNavbar";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { addAuth } from '../../features/auth/authSlice';
import LocalStorage from '../../methods/LocalStorage';

export const Thankyou = () => {
  const [accessUrlEvent, setAccessUrlEvent] = useState('');
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const { loadState } = LocalStorage;

  useEffect(() => {
    const accessSourceEvent = location.hash.split('=')[0];
    setAccessUrlEvent(accessSourceEvent);

    if (true) {
      //add subscription to user
    }
  }, [])

  useEffect(() => {
    const authPayload = loadState('ac_batptl');
    //todo: check if authPayload is valid and it matches the incoming accParam

    if (authPayload) {
      dispatch(addAuth(authPayload));
    }
  }, [])

  if (!auth || auth.authenticated === false) {
    return (<>
      <h2>No Auth</h2>
    </>);
  }
  if (auth || auth.subscription === undefined || auth.subscription === null || auth.subscription === false) {
    navigate('/setup/tybus');
  }

  return (
    <Box
      height="full"
      width="full">
      <TopNavbar />
      <Flex height="100vh">
        <Box
          height="full"
          width={{
            md: '14rem',
            xl: '18rem',
          }}
          display={{
            base: 'none',
            lg: 'initial',
          }}
          overflowY="auto"
          borderRightWidth="1px"
        >
          <Navbar />

        </Box>
        <Flex justify="center" w={'100%'}>
          Hello
        </Flex>
      </Flex>
    </Box>
  )
}
