import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  Container,
  Heading,
  HStack,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
  Stack,
  Text,
  Flex,
  Wrap,
  WrapItem
} from '@chakra-ui/react'
import { Logo } from '../../components/Logos/Logo'
import { useNavigate } from "react-router-dom";
import SignIn from "../../components/Auth/signIn";

const Social = ({posts,todos, creative, events}) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);


  return (
    <Box
          as="section"
          py={{
            base: '4',
            md: '8',
          }}
          >
            <Container >
              <Box
              bg="bg.surface"
              boxShadow="sm"
              borderRadius="lg"
              p={{
              base: '4',
              md: '6',
              }}
              className='sandra-tools-shepherd'
              >
                <Stack spacing="5">
                  <Stack spacing="1">
                    <Flex w="100%" align="baseline" justify="space-between">
                      <Text fontSize="2xl" as="h1" casing="uppercase">Social</Text>
                      <Button
                        size="sm"
                        color="#99999"
                        variant="outline"
                        onClick={() => navigate('/social')}
                        // leftIcon={<AddIcon />}
                      >
                        Go to Social
                      </Button>
                    </Flex>
                    <Text textStyle="sm" color="fg.muted">
                    Here you can use the same AI that powers your Artificial Employees to get work done now.   
                    </Text>
                  </Stack>
                  <Stack
                  spacing="3"
                  direction={{
                  base: 'column',
                  sm: 'row',
                  }}
                  >
                    <Wrap spacing={100}>
                  <WrapItem>
                    <Box>
                    <Stat>
                        <StatLabel>Total Drafts</StatLabel>
                        <StatNumber><a color={'primary'} onClick={() => navigate('/social')}>{creative.length}</a></StatNumber>
                        <StatHelpText>Ready for feedback</StatHelpText>
                    </Stat>
                    </Box>
                    </WrapItem>
                    <WrapItem>
                    <Box>
                    <Stat>
                        <StatLabel>Total Post Scheduled</StatLabel>
                        <StatNumber>{events.filter(e => e.type === 'post-schedule').length}</StatNumber>
                        <StatHelpText>Ready for review</StatHelpText>
                    </Stat>
                    </Box>
                    </WrapItem>  
                    
                  
                  </Wrap>

                    
                  </Stack>
                </Stack>
              </Box>
            </Container>
          </Box>
  )
}

export default Social;