import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Textarea, Switch, Text, FormLabel,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  useDisclosure,
  Flex,
  useToast
} from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';
import { PiMagicWandFill } from 'react-icons/pi';
import { BsEye } from 'react-icons/bs';
import PostApi from '../../../methods/PostsApi';
import AutoEditApi from '../../../methods/AutoEditApi';
import ScheduledImageGallery from '../../Media/ScheduledImageGallery';
import { updateEvent } from '../../../../src/features/events/eventsSlice';

function IconDrawer({ item, auth, type }) {
  const imagesBucketURL = `${process.env.REACT_APP_SUPABASE_PROJECT_URL}/storage/v1/object/public/users_files/`;

  const gallery = useSelector((state) => state.gallery);
  const posts = useSelector((state) => state.posts);
  const [imagesData, setImagesData] = useState([]);
  const [size, setSize] = React.useState('');
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [imgCollection, setImgCollection] = useState([]);
  const [schedulePostValue, setSchedulePostValue] = useState(item.data.output || item.data.body)

  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const userId = auth.user_metadata.uiuid
  const { autoEditPost } = AutoEditApi;
  const toast = useToast();

  const handleInputChange = (e) => {
    const inputValue = e.target.value
    setSchedulePostValue(inputValue)
  }

  const showToastMessage = (toastStatus, title) => toast({
    position: 'top',
    title,
    status: toastStatus,
    duration: 5000,
    isClosable: true
  })

  const imgSrc = imagesBucketURL + userId + "/";

  const log = () => {
    const postToUpdate = posts.filter(p => p.id === item.id)
    const updatedPost = { ...postToUpdate[0], data: { ...postToUpdate[0].data, body: schedulePostValue, img: imgCollection } };

    PostApi.updatePost(updatedPost, auth);
    dispatch(updateEvent(updatedPost))
  };

  const imageRenderer = (gallery) => {
    const readyImages = gallery.map((img) => ({
      src: `${imgSrc + img.name}`,
      width: 2,
      height: 2
    }))
    return readyImages;
  }

  const postImgRenderer = (image) => {
    const postReadyImages = image.map((img) => ({
      src: `${imgSrc + img}`,
      width: 2,
      height: 2
    }))
    return postReadyImages;
  }

  const handleAddImgToState = (img) => {
    const toRemove = `${imagesBucketURL + userId + "/"}`;
    const imgSrc = img.replace(toRemove, '')

    setImgCollection((prevImgs) => {
      if (prevImgs.includes(imgSrc)) {
        return prevImgs.filter((obj) => obj !== imgSrc);
      } else {
        return [...prevImgs, imgSrc]
      }
    })
  }

  useEffect(() => {
    if (gallery) {
      const updatedImages = imageRenderer(gallery);
      setImagesData(updatedImages);
    }
  }, [gallery]);

  const handleClick = (newSize) => {
    setSize(newSize)
    onOpen()
  }

  const handleCloseViewCard = () => {
    setSchedulePostValue(item.data.output || item.data.body)
    onClose();
    setIsReadOnly(true)
  }

  const sizes = ['xs', 'sm', 'md', 'lg', 'xl', 'full']

  const autoEditScheduledPost = async () => {
    const autoEditUiuid = uuidv4();
    const { id, data, topic, reaction, schedule_type, remove, ...autoEditObject } = item;

    autoEditObject.uiuid = autoEditUiuid;
    autoEditObject.post_id = id;
    autoEditObject.title = topic;
    autoEditObject.type = 'post-revision';
    autoEditObject.resource_type = 'post';
    autoEditObject.resource_id = id;

    await autoEditPost(autoEditObject);
    showToastMessage('success', 'Sandra is editing your content for you');
    onClose();
  }

  return (
    <>
      <BsEye
        onClick={() => handleClick('xl')}
        size={'1.5rem'}
        key={'xl'}
        m={4}
        style={{ cursor: 'pointer' }}
      >
        {`Open ${'xl'} Drawer`}
      </BsEye>

      <Drawer onClose={handleCloseViewCard} isOpen={isOpen} size={size}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <Text>{item.topic}</Text>

            {['social', 'blog', 'schedule'].includes(type) && (
              <Flex gap="14" alignItems="center">
                <Flex direction="column" alignItems="center">
                  <FormLabel htmlFor='auto-edit'>Auto Edit</FormLabel>
                  <PiMagicWandFill size='26px' style={{ cursor: 'pointer' }} onClick={autoEditScheduledPost} />
                </Flex>
                <Flex direction="column">
                  <FormLabel htmlFor='isChecked'>Edit Mode</FormLabel>
                  <Switch colorScheme='black' size='lg' onChange={() => setIsReadOnly(!isReadOnly)} />
                </Flex>
              </Flex>
            )}
          </DrawerHeader>
          <DrawerBody>
            {!isReadOnly &&
              <>
                <Textarea
                  value={schedulePostValue}
                  onChange={handleInputChange}
                  size="lg"
                  height={'500px'}
                  isInvalid
                />
                <Button colorScheme='black' variant='solid' mt={4} mb={4} onClick={log}>
                  Save Changes
                </Button>
                <ScheduledImageGallery media={imagesData} handleAddImgToState={handleAddImgToState} />
              </>
            }

            {isReadOnly === true &&
              <>
                <Textarea
                  value={item.data.output || item.data.body}
                  isReadOnly
                  size="lg"
                  height={'500px'}
                />

                {item.data.img && item.data.img.length > 0
                  ? <ScheduledImageGallery media={postImgRenderer(item.data.img)} />
                  : <div><br /><br /><Text style={{ textAlign: 'center', fontStyle: 'italic', color: 'grey' }}>There are no images attached to this post.</Text></div>}

              </>
            }
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default IconDrawer;