const getAllContent = async (industry) => {
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: 'GET',
    headers: reqHeaders
  }

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/creative?filter=${JSON.stringify([{ industry: industry.value }])}`, requestOptions);
  const dataResult = await result.json();

  return dataResult;
}

const getContentById = async (payload) => {
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: 'GET',
    headers: reqHeaders
  }

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/creative/${payload.id}`, requestOptions);
  return result.json();

}

const addContent = async (payload) => {
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    "status": payload.status,
    "type": payload.type,
    "data": {
      "title": payload.data.title,
      "uiuid": payload.data.uiuid
    }
  });

  const requestOptions = {
    method: 'POST',
    headers: reqHeaders,
    body: raw
  }

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/creative`, requestOptions);
  return result.json();
}

const deleteContentById = async (payload) => {
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: 'DELETE',
    headers: reqHeaders,
  }

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/creative/${payload.id}`, requestOptions);
  return result.json();
}


const updateContent = async (payload) => {
  // thumbs up / down funcionality for work items
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify(payload);

  const requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/creative/${payload.id}`, requestOptions);
  return result.json();

}






export default {
  getAllContent,
  getContentById,
  addContent,
  deleteContentById,
  updateContent
}