import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Flex,
  Button,
  Stack,
  Text,
  Badge,
  Divider,
  Modal,
  ModalContent,
  ModalBody,
  ModalOverlay,
  ModalCloseButton,
  ModalHeader,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  useToast,
  Spinner,
  Container,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import { FaTwitter, FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa';
import Threads from '../../Logos/Threads';
import LocalStorage from '../../../methods/LocalStorage';
import Gateway from '../../../methods/Gateway';
import ToolsApi from '../../../methods/ToolsApi';
import { SocialProviders } from '../../../config';
import { useNavigate, Link } from "react-router-dom";
import { Facebook, Instagram, LinkedIn, Twitter, YouTube } from '@mui/icons-material';

const { startTwitterAuth } = Gateway;
const { addTool, Tool } = ToolsApi

const SocialMediaLinkButtons = ({ toggleShowDashboard }) => {
  const [loading, setLoading] = useState(false);
  const [modalAccount, setModalAccount] = useState({});
  const navigate = useNavigate();

  const toast = useToast();
  const auth = useSelector((state) => state.auth);
  const { saveState, loadState } = LocalStorage;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const twitterLinked = loadState('twitterLinked') || false;
  const fbLinked = loadState('fbLinked') || false;
  const igLinked = loadState('igLinked') || false;
  const ytLinked = loadState('ytLinked') || false;
  const liLinked = loadState('liLinked') || false;
  const authPayload = loadState('ac_batptl');

  const showToastMessage = (toastStatus, title) => toast({
    position: 'top',
    title,
    status: toastStatus,
    duration: 5000,
    isClosable: true
  })

  const startTwitterAuthRequest = async () => {
    setLoading(true);

    const response = await startTwitterAuth(auth);
    if (response.redirectUrl) {
      const { redirectUrl, oauthRequestToken, oauthRequestTokenSecret } = response;
      if (redirectUrl) {
        saveState('oauthRequestToken', oauthRequestToken)
        saveState('oauthRequestTokenSecret', oauthRequestTokenSecret)
        window.location.href = redirectUrl;
      }
    }
  }

  const handleSocialAccountCredentials = async (event) => {
    event.preventDefault();
    setLoading(true)

    const formData = Array.from(event.target).map((input) => (
      { key: input.name, value: input.value }
    ));
    const socialUser = formData[0].value;

    const toolPayload = new Tool({
      toolUser: `${modalAccount.name}User`,
      toolAccess: {
        username: socialUser
      },
      type: modalAccount.name,
      status: 'read-only'
    }, authPayload.data);

    const toolResponse = await addTool(toolPayload, authPayload.data);
    if (toolResponse.statusCode === 201) {
      setLoading(false)
      onClose();
      switch (modalAccount.name) {
        case 'facebook':
          saveState('fbLinked', true);
          break;
        case 'instagram':
          saveState('igLinked', true);
          break;
        case 'youtube':
          saveState('ytLinked', true);
          break;
        case 'linkedIn':
          saveState('liLinked', true);
          break;
        default:
          return;
      }
      showToastMessage('success', 'Your data have been stored successfully!')
    } else {
      setLoading(false)
      onClose();
      showToastMessage('error', 'There was a problem with the request. Please try again')
    }
  }


  const handleAccountConnect = (network) => {
    const socialNetwork = SocialProviders.find(item => item.name === network);
    setModalAccount(socialNetwork);
    onOpen();
  }


  return (
    <>
      <Box
        as="section"
        py={{
          base: '4',
          xs: '1',
          md: '8',
        }}
      >
        <Container p="0">
          <Box
            bg="bg.surface"
            boxShadow="sm"
            borderRadius="lg"
            p={{
              base: '4',
              md: '6',
            }}
          >
            <Stack spacing="2">
              <Stack
                spacing="3"
                direction={{
                  base: 'column',
                  sm: 'row',
                }}
              >
                <Wrap spacing={6}>
                  <WrapItem>
                    <Button
                      colorScheme="twitter"
                      leftIcon={<FaTwitter color={twitterLinked ? 'black' : 'white'} />}
                      iconSpacing="3"
                      onClick={twitterLinked ? () => showToastMessage('info', 'Already Connected!') : startTwitterAuthRequest}
                      isDisabled={twitterLinked}
                    >
                      {
                        twitterLinked
                          ? 'Linked'
                          : 'Connect'
                      }
                      <Badge ml='1' fontSize='0.8em' m={2} colorScheme='black'>
                        Realtime
                      </Badge>
                    </Button>
                  </WrapItem>

                  <Button
                    colorScheme="pink"
                    leftIcon={<Instagram color='white' />}
                    iconSpacing="3"
                    onClick={igLinked ? () => showToastMessage('info', 'Already Connected!') : () => handleAccountConnect('instagram')}
                    isDisabled={igLinked}
                  >
                    {
                      igLinked
                        ? 'Linked'
                        : 'Connect'
                    }
                    <Badge ml='1' fontSize='0.8em' m={2} colorScheme='black'>
                      Follow Only
                    </Badge>
                  </Button>

                  <Button
                    colorScheme='facebook'
                    leftIcon={<Facebook color='white' />}
                    iconSpacing="3"
                    onClick={fbLinked ? () => showToastMessage('info', 'Already Connected!') : () => handleAccountConnect('facebook')}
                    isDisabled={fbLinked}
                  >
                    {
                      fbLinked
                        ? 'Linked'
                        : 'Connect'
                    }
                    <Badge ml='1' fontSize='0.8em' m={2} colorScheme='black'>
                      Follow Only
                    </Badge>
                  </Button>

                  <Button
                    colorScheme='red'
                    leftIcon={<YouTube color='white' />}
                    iconSpacing="3" onClick={ytLinked ? () => showToastMessage('info', 'Already Connected!') : () => handleAccountConnect('youtube')}
                    isDisabled={ytLinked}
                  >
                    {
                      ytLinked
                        ? 'Linked'
                        : 'Connect'
                    }
                    <Badge ml='1' fontSize='0.8em' m={2} colorScheme='black'>
                      Follow Only
                    </Badge>
                  </Button>

                  <Button
                    colorScheme='linkedin'
                    leftIcon={<LinkedIn color='white' />}
                    iconSpacing="3" onClick={liLinked ? () => showToastMessage('info', 'Already Connected!') : () => handleAccountConnect('linkedIn')}
                    isDisabled={liLinked}
                  >
                    {
                      liLinked
                        ? 'Linked'
                        : 'Connect'
                    }
                    <Badge ml='1' fontSize='0.8em' m={2} colorScheme='black'>
                      Follow Only
                    </Badge>
                  </Button>

                  <Button colorScheme='gray' leftIcon={<Threads />} iconSpacing="1" disabled >
                    <Badge ml='1' fontSize='0.8em' m={2} colorScheme='black'>
                      Coming Soon
                    </Badge>

                  </Button>

                </Wrap>

              </Stack>
            </Stack>
          </Box>



          <Box w="100%" h="100px" mt="3%">
            <Flex justify="flex-end" align="center" w="100%">
              <Wrap >
                <WrapItem m={1}>
                  <Button colorScheme="black" onClick={() => navigate('/dashboard')}>I'm good to go</Button>
                </WrapItem>
              </Wrap>
            </Flex>
          </Box>
        </Container>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text fontWeight="bold" fontSize="xl" textAlign="center" py="10px">Link Account</Text>
            <Divider />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box w="100%" h="100%" pb="10%">
              <Flex align="center" w="100%" h="100%">
                <Box w="15%">
                  {modalAccount.faIcon}
                </Box>
                <Box w="85%">
                  <Flex direction="column" w="100%">
                    <Text fontSize="xl" casing="capitalize">{modalAccount.name}</Text>
                    <Text fontSize="xs">Enter your handle to connect your account</Text>
                  </Flex>
                </Box>
              </Flex>
            </Box>
            {
              loading ? (
                <Flex w="100%" h="100%" justify="center" align="center" minH="150px">
                  <Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='blue.500'
                    size='xl'
                  />
                </Flex>
              ) : (
                <form onSubmit={handleSocialAccountCredentials}>
                  <Stack spacing="5">
                    <FormControl isRequired>
                      <FormLabel htmlFor="name">Username</FormLabel>
                      <Input id="username" name="username" type="text" />
                    </FormControl>
                  </Stack>
                  <Stack mt="10" pb="5">
                    <Button colorScheme={modalAccount.colorScheme} type="submit">
                      Submit
                    </Button>
                  </Stack>
                </form>
              )
            }
          </ModalBody>
        </ModalContent>
      </Modal>

    </>
  )

}

export default SocialMediaLinkButtons;



