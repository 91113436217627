import { createSlice } from '@reduxjs/toolkit';

export const gallerySlice = createSlice({
  name: 'gallery',
  initialState: [],
  reducers: {
    images:  (state, action) => {
      const images = action.payload;
      state = [...images];
      return state;
    },
  },
});

// this is for dispatch
export const { images } = gallerySlice.actions;

// this is for configureStore
export default gallerySlice.reducer;
