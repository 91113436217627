import React from 'react';
import { Box, Flex, Heading } from '@chakra-ui/react';
import { MoonIcon } from '@chakra-ui/icons';

const ImageGalleryEmptyState = () => {

  return (
    <Box w="100%" h="100%" color="gray.300" pt={5}>
      <Flex direction="column" align="center" justify="flex-end">
        <Box w="100%" h="85px" textAlign="center">
          <Heading as="h1" size="md">You don't have any media</Heading>
        </Box>
        
        <MoonIcon boxSize={55} />
      </Flex>
    </Box>
  )

}

export default ImageGalleryEmptyState;