import { Box, Flex } from '@chakra-ui/react'
import { useEffect } from 'react';
import AddSkill from '../../components/Skills/addSkill';
import { Navbar } from '../../components/Navs/Navbar';
import { TopNavbar } from "../../components/Navs/TopNavbar";
import { useSelector } from "react-redux";

export const Chat = () => {
    const skills = useSelector((state) => state.skills);
    const auth = useSelector((state) => state.auth);

    useEffect(() => {
    }, [])

    if (!auth || auth.authenticated === false) {
        return (<>
            <h1>Shucks, something went wrong!</h1>
            <pre>{JSON.stringify(auth, 2)}</pre>
        </>);
    }

    return (
        <Box
            height="full"
            width="full">
            <TopNavbar />
            <Flex height="100vh">
                <Box
                    height="full"
                    width={{
                        md: '14rem',
                        xl: '18rem',
                    }}
                    display={{
                        base: 'none',
                        lg: 'initial',
                    }}
                    overflowY="auto"
                    borderRightWidth="1px"
                >
                    <Navbar />

                </Box>
                <Flex justify="center" w={'100%'}>
                    <AddSkill />
                    <h1>Skills</h1>
                    <div>
                        <ul>
                            {skills.map((skills) => (
                                <li key={skills.id}>
                                    <span>{skills.text}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </Flex>
            </Flex>
        </Box>
    )
}
