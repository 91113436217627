import React, { useEffect } from 'react';

const ContactForm = () => {

  useEffect(() => {
    const hubScript = document.createElement('script');
    hubScript.src = 'https://js.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(hubScript);

    hubScript.addEventListener('load', () => {
      if(window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "8576785",
          formId: "5cbd8fa6-db9b-4aec-aa5a-7ed444e1bf02",
          target: '#hubspotForm'
        })
      }
    })
  }, []);

  return <div id="hubspotForm"></div>

}

export default ContactForm;