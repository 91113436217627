import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { supabase } from '../../methods/Supabase';
import {
  Icon,
  Menu,
  MenuButton,
  IconButton,
  Box,
  Flex,
  Spacer,
  Switch,
  Heading,
  ButtonGroup,
  Container
} from '@chakra-ui/react';
import { ChevronLeftIcon, HamburgerIcon } from '@chakra-ui/icons';
import { v4 as uuidv4 } from 'uuid';
import { isNil } from 'lodash';
import Shepherd from 'shepherd.js';
import { MdGroupWork } from 'react-icons/md';

import LocalStorage from "../../methods/LocalStorage";
import { addAuth } from "../../features/auth/authSlice";
import { setTodos } from '../../features/todos/todosSlice';
import { setTools } from '../../features/tool/toolSlice';
import { setContent } from '../../features/content/contentSlice';
import { setPosts } from '../../features/post/postSlice';
import { addBlog, setBlogs } from '../../features/blog/blogSlice';
import { setReactions } from '../../features/reaction/reactionSlice'
import { images } from '../../features/gallery/gallerySlice';

import TodosApi from '../../methods/TodosApi';
import ToolsApi from '../../methods/ToolsApi';
import ContentApi from '../../methods/ContentApi';
import PostsApi from '../../methods/PostsApi';
import BlogsApi from '../../methods/BlogsApi';
import ReactionsApi from '../../methods/ReactionsApi';
import UsersApi from '../../methods/UsersApi';
import TopicsApi from '../../methods/TopicsApi';
import { Sidebar } from "../../components/ACSidebars";
import { SummaryOveriew } from "../../components/ACObjectives";
import { ToolsOveriew } from "../../components/ACTools";
import { LgTablesTabs } from "../../components/ACTabs";
import navigationData from "../../data/navigation";
import { AgentFullWidthCard } from '../../components/AgentFullWidthCard';
import { ContentGridSummary } from '../../components/ACGrids';
import { ScheduleSummary } from '../../components/ACScheduleSummary';
import EventsApi from '../../methods/EventsApi';
import { setEvents, addNewEvent } from '../../features/events/eventsSlice';
import { setTopics } from '../../features/topic/topicSlice';
import { fetchGallery } from '../../utils/fetchGallery';
import _ from 'lodash';

import 'shepherd.js/dist/css/shepherd.css';

const { getAllEvents } = EventsApi;
const { getAllTodos } = TodosApi;
const { getAllTools } = ToolsApi;
const { getAllContent } = ContentApi;
const { getAllPosts } = PostsApi;
const { getAllBlogs, createBlog } = BlogsApi;
const { getUserById } = UsersApi;
const { getAllReactions } = ReactionsApi;
const { getAllTopics } = TopicsApi;

const Social = () => {
  // Need to persist on database
  const [showDashboard, setShowDashboard] = useState(false);
  const [twitterUser, setTwitterUser] = useState({})
  const [userIndustry, setUserIndustry] = useState('');
  const [listView, setListView] = useState('creative');
  const [quickMode, setQuickMode] = useState(false);
  const [closeSideBar, setCloseSideBar] = useState(false);

  const auth = useSelector((state) => state.auth);
  const blogs = useSelector((state) => state.blogs);
  const posts = useSelector((state) => state.posts);
  const creative = useSelector((state) => state.content);
  const events = useSelector((state) => state.events);
  const todos = useSelector((state) => state.todos);

  const { loadState, saveState } = LocalStorage;
  const location = useLocation();
  const dispatch = useDispatch();

  const userId = auth?.id || ''
  const queryParams = new URLSearchParams(location.search);
  const accParam = queryParams.get('acc');
  const hasSeenSocialSetup = loadState('hasSeenSocialSetup')

  const getContentList = (userIndustry) => {
    if (userIndustry === 'creative') return creative;
    if (userIndustry === 'blog') return posts.filter(p => p.type === 'blog-post');
    if (userIndustry === 'social') return posts.filter(p => p.type === 'social');
    if (userIndustry === 'schedule') return _.sortBy(events.map(p => {
      return { ...p.posts, schedule_date: p.schedule_date, schedule_type: p.type, target: p.target };
    }), 'schedule_date');
    return [];
  }

  useEffect(() => {
    const eventsChannel = supabase
      .channel('realtime posts')
      .on('postgres_changes', {
        event: 'INSERT',
        schema: 'public',
        table: 'events'
      }, async (payload) => {
        const newEvent = payload.new
        const { post_id } = newEvent

        const postsResponse = await getAllPosts(auth);
        const newEventPost = postsResponse.data.filter(p => p.id === post_id)

        const updateEvent = { ...newEvent, posts: newEventPost[0] }
        dispatch(addNewEvent(updateEvent))
      }).subscribe();

    return () => {
      supabase.removeChannel(eventsChannel)
    }
  }, [dispatch, auth])



  useEffect(() => {
    const setContentList = async (userIndustry) => {
      const contentResponse = await getAllContent(userIndustry);
      if (contentResponse) {
        const { data: contentData } = contentResponse;
        const reactionResponse = await getAllReactions();
        dispatch(setReactions({ data: reactionResponse.data }))

        const filteredReactions = reactionResponse.data.filter(reaction => {
          return contentData.some(content => content.id === Number(reaction.resource_id))
        })

        contentData.forEach(content => {
          let likeCounter = 0;

          filteredReactions.forEach(r => {
            if (Number(r.resource_id) === content.id) {
              likeCounter++;
            }
          });

          const newContent = Object.assign({}, content);
          newContent.likeCount = likeCounter;
          contentData[contentData.indexOf(content)] = newContent;
        });

        const userId = auth.user_metadata.uiuid
        const userFilteredReactions = filteredReactions.filter(reaction => reaction.user_uiuid === userId)

        const formattedContent = contentData.map((content) => {
          const contentId = content.id
          const reactionItem = userFilteredReactions.find(r => r.resource_id === contentId.toString())
          if (!isNil(reactionItem) && reactionItem.resource === 'creative') {
            return {
              ...content,
              reaction: reactionItem.reaction,
            }
          }
          return {
            ...content,
            reaction: null,
          }
        })
        dispatch(setContent({ data: formattedContent }))
        return
      }
    }
    const setImages = async (userId) => {
      const fetchedImages = await fetchGallery(userId);
      dispatch(images(fetchedImages));
    }
    const getUser = async () => {
      const userResponse = await getUserById({ id: userId })
      const userObj = userResponse.data.find(user => user.id === userId)
      const user_uiuid = userObj.uiuid
      const industry = userObj.industry;
      if (industry && industry !== null) setUserIndustry(industry.value);
      if (industry) {
        setContentList(industry);
        setImages(user_uiuid)
      }
    }
    if (userId) {
      getUser()
    }
  }, [userId, dispatch])

  useEffect(() => {
    const authPayload = loadState('ac_batptl');

    const setTodoList = async () => {
      const todosResponse = await getAllTodos();
      if (todosResponse) {
        const { data: todosData } = todosResponse;
        const reactionResponse = await getAllReactions();

        if (reactionResponse.data) {
          const { data } = reactionResponse;
          const formattedTodos = todosData.map((todo) => {
            const todoId = todo.id;
            const reactionItem = data.find(item => item.resource_id === todoId.toString())
            if (!isNil(reactionItem) && reactionItem.resource === 'todo') {
              return {
                ...todo,
                reaction: reactionItem.reaction
              }
            }
            return todo
          })
          dispatch(setTodos({ data: formattedTodos }))
          return
        }
        dispatch(setTodos({ data: todosData }))
      }
    }

    const setToolsList = async (auth) => {
      const toolsResponse = await getAllTools(auth);


      if (toolsResponse) {
        const { data: toolsData } = toolsResponse;
        if (toolsData?.length > 0) {
          localStorage.setItem('twitterToolId', toolsData.find(tool => tool.type === 'twitter')?.id);
          setTwitterUser(toolsData.find(tool => tool.type === 'twitter')?.config?.toolUser);
        }
        dispatch(setTools({ data: toolsData }))
      }
    }

    const setPostsList = async (auth) => {
      const postsResponse = await getAllPosts(auth);
      const tweetsResponse = [];

      const data = tweetsResponse;

      const tweetsView = data.map((tweet) => {
        const { created_at, payload, status, uiuid, user_uiuid } = tweet;
        return { created_at, data: payload, status, topic: 'general', type: 'social', scheduled: null, reaction: 'like', id: uiuid, uiuid, user_uiuid };
      })

      if (postsResponse) {
        const { data: postsData } = postsResponse;
        // const reactionResponse = await getAllReactions();

        // if (reactionResponse.data) {
        // const { data: reactionData } = reactionResponse;
        // const formattedContent = postsData.map((post) => {
        //   const postId = post.id;
        //   const reactionItem = reactionData.find(item => item.resource_id === postId.toString())
        //   if (!isNil(reactionItem) && (reactionItem.resource === 'blog' || reactionItem.resource === 'social')) {
        //     return {
        //       ...post,
        //       reaction: reactionItem.reaction
        //     }
        //   }
        //   return post
        // })
        // dispatch(setPosts({ data: [...formattedContent, ...tweetsView] }))
        // return
        // }
        dispatch(setPosts({ data: [...postsData, ...tweetsView] }))
      }
    }

    const setBlogsList = async (auth) => {
      const blogsResponse = await getAllBlogs(auth);

      if (blogsResponse.data.length === 0) {
        setTimeout(async () => {
          const result = await createBlog(auth, {
            name: auth.user.user_metadata.organization,
            type: "notion",
            status: 0,
            uiuid: uuidv4(),
            user_uiuid: auth.user.user_metadata.uiuid
          })

          if (result) {
            dispatch(addBlog(result.data[0]))
          }
        }, 10000);
      }


      if (blogsResponse) {
        dispatch(setBlogs({ data: blogsResponse.data }))
      }
    }

    const setAllEvents = async (auth) => {
      const eventsResponse = await getAllEvents(auth);
      const { data: eventsData } = eventsResponse;

      dispatch(setEvents({ data: [...eventsData] }));
      return;
    }

    const setAllTopics = async (auth) => {
      const topicsResponse = await getAllTopics(auth)
      const { data: topicsData } = topicsResponse

      dispatch(setTopics({ data: [...topicsData] }))
      return
    }
    //todo: check if authPayload is valid and it matches the incoming accParam

    const setIntercom = async (auth) => {
      const { email, sub } = auth.user
      const { name } = auth.user.user_metadata

      window.Intercom('boot', {
        api_base: "https://api-iam.intercom.io",
        app_id: "lv6y801r",
        email,
        user_id: sub,
        name,
        created_at: Date.now(),
      });

      if (!localStorage.getItem('intercomShownAlready')) {
        localStorage.setItem('intercomShownAlready', 'true');
        window.Intercom('show')
      }
    }

    if (authPayload) {
      if (!auth?.user_metadata) dispatch(addAuth(authPayload));
      setTodoList();
      setPostsList(authPayload.data);
      setToolsList(authPayload.data);
      setBlogsList(authPayload.data);
      setIntercom(authPayload.data);
      setAllEvents(authPayload.data);
      setAllTopics(authPayload.data)

      setTimeout(() => {
        window.Intercom('hide');
      }, 5000);
    }

    const handleClickOutside = (event) => {
      if (!event.target.closest('.intercom-container') && !event.target.closest('.intercom-comment')) {
        window.Intercom('hide');
      }
    };
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    }
  }, [dispatch, loadState, auth?.user_metadata])


  useEffect(() => {
    if (isNil(hasSeenSocialSetup)) {
      saveState('hasSeenSocialSetup', true)
    } else {
      setShowDashboard(true)
    }

    // We need to persist social account linkup on db for conditional rendering
    const socialUserCheck = location.state?.twitterUser || loadState('twitterToolId');
    if (socialUserCheck) {
      const twitterUser = location.state?.twitterUser || loadState('twitterUser')
      setTwitterUser(twitterUser);
      //saveState('twitterLinked', true)
    }
  }, [loadState, saveState, location.state?.twitterUser, hasSeenSocialSetup])

  const toggleShowDashboard = () => setShowDashboard(!showDashboard);

  const dashboardTour = new Shepherd.Tour({
    useModalOverlay: true,
    defaultStepOptions: {
      classes: 'shadow-md bg-purple-dark',
      scrollTo: true,
      exitOnEsc: true,
      cancelIcon: {
        enabled: true
      }
    }
  });

  dashboardTour.addStep({
    id: 'initial',
    text: `Let me show you around your dashboard.`,
    scrollTo: {
      behavior: 'smooth',
      block: 'center'
    },
    buttons: [{
      text: 'Next',
      action: dashboardTour.next
    }]
  });

  dashboardTour.addStep({
    id: 'step1',
    text: `Introducing Sandra, an Artificial Employee*
    <br/>
    Your AI Powered Social Media Manager
    <br/>

    *Patent Pending`,
    scrollTo: {
      behavior: 'smooth',
      block: 'center'
    },
    attachTo: {
      element: () => document.querySelector('.sandra-card'),
      on: 'bottom'
    },
    buttons: [{
      text: 'Next',
      action: dashboardTour.next
    }]
  });

  dashboardTour.addStep({
    id: 'step2',
    text: `Let's start by setting Sandra's 30, 60, and 90 objectives. It's important to have clear goals to work towards.`,
    scrollTo: {
      behavior: 'smooth',
      block: 'center'
    },
    attachTo: {
      element: '.ai-objectives-shepherd',
      on: 'bottom'
    },
    buttons: [{
      text: 'Next',
      action: dashboardTour.next
    }]
  });

  dashboardTour.addStep({
    id: 'step3',
    text: `Next, we need to provide Sandra with the onboarding and Social Media tools she needs to succeed in her new role.`,
    scrollTo: {
      behavior: 'smooth',
      block: 'center'
    },
    attachTo: {
      element: '.sandra-tools-shepherd',
      on: 'bottom'
    },
    buttons: [{
      text: 'Next',
      action: dashboardTour.next
    }]
  });

  dashboardTour.addStep({
    id: 'step5',
    text: `Finally, check out the Work section to get visibility into tasks, descriptions, and the opportunity to provide feedback. 
    This will help you stay on top of everything Sandra's doing.`,
    scrollTo: {
      behavior: 'smooth',
      block: 'center'
    },
    attachTo: {
      element: '.work-table-shepherd',
      on: 'bottom'
    },
    buttons: [{
      text: 'Next',
      action: dashboardTour.next
    },
    {
      text: 'Exit',
      action: dashboardTour.cancel
    }]
  });

  useEffect(() => {
    const element = document.getElementById('sandra-shepherd-tour');

    if (element && window.location.pathname === '/dashboard' && !localStorage.getItem('dashboardTourShown')) {
      localStorage.setItem('dashboardTourShown', 'true');
      dashboardTour.start();
    }
  });


  if (!auth || auth.authenticated === false) {
    return (
      <>
        <h1>Shucks, something went wrong!</h1>
        <pre>{JSON.stringify(accParam, 2)}</pre>
      </>
    );
  }

  return (
    <>
      <Box position="sticky" top="0" zIndex="1" bg="white" p={4}>
        <Flex>
          <Box><Icon as={MdGroupWork} w={8} h={8} color='black' /></Box>
          <Spacer />
          <Box>
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={closeSideBar ? <HamburgerIcon /> : <ChevronLeftIcon />}
                // varia nt={"ghost"}
                onClick={() => setCloseSideBar(closeSideBar ? false : true)}
              />
              {/* You can add MenuList and MenuItems here for dropdown options */}
            </Menu>
          </Box>
        </Flex>
      </Box>
      <Flex>
        <Box position="sticky" top="0" height="calc(100vh - 4rem)" width={'max-content'}
          display={(closeSideBar || quickMode) ? 'none' : 'block'} >
          <Sidebar navigationData={navigationData} />
        </Box>
        <Box width={{
          base: (closeSideBar || quickMode) ? '100%' : '80%',
          sm: (closeSideBar || quickMode) ? '100%' : '80%',
          md: (closeSideBar || quickMode) ? '100%' : 'calc(100% - 80px)',
          lg: (closeSideBar || quickMode) ? '100%' : 'calc(100% - 80px)'
        }} height="full">
          {/* {
            showDashboard
              ? <Work twitterUser={twitterUser} blogs={blogs} auth={auth} userIndustry={userIndustry} toggleShowDashboard={toggleShowDashboard} />
              : <SocialAccounts toggleShowDashboard={toggleShowDashboard} />
          } */}

          <AgentFullWidthCard twitterUser={twitterUser} blogs={blogs} auth={auth} userIndustry={userIndustry} toggleShowDashboard={toggleShowDashboard} ae={'social'} />

          {/* <Flex alignItems='center' mt="40px">
          <Container>
              <Box>
              <SocialInbox posts={posts} todos={todos}  creative={creative} ></SocialInbox>
              </Box>
            </Container>
          </Flex> */}
          <Flex alignItems='center' mt="40px">
            
            <Container>
              <Box
                bg="bg.surface"
                boxShadow="sm"
                borderRadius="lg"
              >
                <LgTablesTabs setListView={setListView} />
                {['creative', 'blog', 'social'].includes(listView) &&
                  <ContentGridSummary type={listView} gridItems={getContentList(listView)} itemsPerPage={8} />
                }
                {['schedule'].includes(listView) &&
                  <ContentGridSummary type={listView} gridItems={getContentList(listView)} itemsPerPage={8} />
                }
              </Box>
            </Container>
          </Flex>
        </Box>
      </Flex>
    </>
  )
}

export default Social;
