import { createSlice } from '@reduxjs/toolkit';

export const applicationSlice = createSlice({
    name: 'applications',
    initialState: [{
        id:1,
        title:'Appfolio',
        status: null,
        configuration:{
            type: 'email',
            two_fa: true,
            description: 'Note: Only an AppFolio user with authority to edit (add, edit, or remove) users can complete the following process.\n' +
                '\n' +
                'Creating a Temporary User in AppFolio\n' +
                'Log in to your AppFolio account.\n' +
                'From the Dashboard, go to the Account menu at lower right, and click New User.\n' +
                'Enter the following information:\n' +
                'Name – First: “Cesar”   Last: “ACU”\n' +
                'Email address – cesar@artificialcompute.ai \n' +
                'User role – Select President.*\n' +
                'Select View all Property Groups & Portfolios -OR- View these Property Groups & Portfolios Only as needed (Note: only included Groups and Portfolios can be synced).\n' +
                'Click Save. Cesar will receive an email containing a link to your company’s AppFolio login page to create a password and log in for the first time.\n' +
                '*For certain accounts, the Property Manager profile is sufficient for us to perform the required actions to set up the integration. Increasingly, however, we have found that the Property Manager profile does not allow us to view and work with Scheduled Reports, central to the integration setup process, which is why we now specify the President profile for all invitations.',
            link: 'https://passport.appf.io/sign_in?idp_type=property&vhostless=true',
        },
    }],
    reducers: {
        addApplication: (state, action) => {
            const {title,config_type, config_two_fa} = action.payload;
            const application = {
                id: Date.now(),
                title,
                configuration:{
                    type:config_type,
                    two_fa: config_two_fa
                }
            };

            state.push(application);
        },
    },
})

// this is for dispatch
export const { addApplication } = applicationSlice.actions;

// this is for configureStore
export default applicationSlice.reducer;
