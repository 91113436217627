const getAllBlogs = async (auth) => {
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");
  reqHeaders.append("Authorization", `Bearer ${auth.session.access_token}`);


  const requestOptions = {
    method: 'GET',
    headers: reqHeaders
  }

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/blogs`, requestOptions);
  return result.json();
}

const getBlogById = async (payload) => {
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: 'GET',
    headers: reqHeaders
  }

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/blogs/${payload.id}`, requestOptions);
  return result.json();

}

const addBlog = async (auth, payload) => {
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify(payload);

  const requestOptions = {
    method: 'POST',
    headers: reqHeaders,
    body: raw
  }

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/blogs`, requestOptions);
  return result.json();
}

const deleteBlogById = async (payload) => {
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: 'DELETE',
    headers: reqHeaders,
  }

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/blogs/${payload.id}`, requestOptions);
  return result.json();
}


const updateBlog = async (payload) => {
  // thumbs up / down funcionality for work items
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify(payload);

  const requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/blogs/${payload.id}`, requestOptions);
  return result.json();
}


export default {
  getAllBlogs,
  getBlogById,
  createBlog: addBlog,
  updateBlog,
  deleteBlogById
}