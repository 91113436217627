const getAllTopics = async (auth) => {
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");
  reqHeaders.append("Authorization", `Bearer ${auth.session.access_token}`);

  const requestOptions = {
    method: 'GET',
    headers: reqHeaders
  }

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/topics`, requestOptions);
  return result.json();
}

const getTopicById = async (payload, auth) => {
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");
  reqHeaders.append("Authorization", `Bearer ${auth.session.access_token}`);

  const requestOptions = {
    method: 'GET',
    headers: reqHeaders
  }

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/topics/${payload.id}`, requestOptions);
  return result.json();

}

const addTopic = async (payload, auth) => {
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");
  reqHeaders.append("Authorization", `Bearer ${auth.session.access_token}`);

  const raw = JSON.stringify(payload);

  const requestOptions = {
    method: 'POST',
    headers: reqHeaders,
    body: raw
  }

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/topics`, requestOptions);
  return result.json();
}

const deleteTopicById = async (payload, auth) => {
  const reqHeaders = new Headers();
  reqHeaders.append("Content-Type", "application/json");
  reqHeaders.append("Authorization", `Bearer ${auth.session.access_token}`);

  const requestOptions = {
    method: 'DELETE',
    headers: reqHeaders,
  }

  const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/topics/${payload.id}`, requestOptions);
  return result.json();
}


const updateTool = async (payload) => {
  // thumbs up / down funcionality for work items
}



export default {
  getAllTopics,
  getTopicById,
  addTopic,
  deleteTopicById
}