import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '@chakra-ui/react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Flex, Spacer, Text } from '@chakra-ui/react'
import qs from 'query-string';
import LocalStorage from '../../methods/LocalStorage';
import Gateway from '../../methods/Gateway';
import ToolsApi from '../../methods/ToolsApi';
import { updateTools } from '../../features/tool/toolSlice';
const { addTool, Tool, updateTool } = ToolsApi

const { startTwitterRequestToken, startTwitterVerify } = Gateway;

const AuthPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { saveState, loadState } = LocalStorage;
  const auth = useSelector((state) => state.auth);
  const tools = useSelector((state) => state.tools);
  const dispatch = useDispatch();

  useEffect(() => {
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    const storedRequestToken = loadState('oauthRequestToken')
    const storedRequestTokenSecret = loadState('oauthRequestTokenSecret')
    const userAuth = loadState('ac_batptl');
    const handleVerifyRequest = async (oauthAccessToken, oauthAccessTokenSecret, requestTokenResponse) => {
      const verifyResponse = await startTwitterVerify({ oauthAccessToken, oauthAccessTokenSecret })
      const { data } = verifyResponse;
      if (data.user) {
        const { user } = data;
        const keys = requestTokenResponse;
        const userData = {
          accessToken: keys.oauthAccessToken,
          secret: keys.oauthAccessTokenSecret,
          user_id: user.id_str,
          screen_name: user.screen_name,
          photo: user.profile_image_url_https.replace("_normal", "")
        }

        const toolPayload = new Tool({
          toolUser: user,
          toolAccess: {
            oauthAccessToken: oauthAccessToken,
            oauthAccessTokenSecret: oauthAccessTokenSecret,
            oauthRequestToken: storedRequestToken,
            oauthRequestTokenSecret: storedRequestTokenSecret,
          },
          type: 'twitter',
          status: 'active'
        }, userAuth);

   
        const toolPayloadId = localStorage.getItem('twitterToolId');
        if(typeof toolPayloadId === 'number'){
          toolPayload.id = toolPayloadId;
          updateTool(toolPayload, userAuth.data);
        }else{
          addTool(toolPayload, userAuth.data);
        }
        
        saveState('twitterUser', userData.data);
        saveState('twitterLinked', true)

        //dispatch(updateTools(toolPayload))

        navigate('/dashboard', {
          state: {
            twitterUser: userData
          }
        })
      }
    }

    const handleReqTokenRequest = async (auth) => {
      const requestTokenResponse = await startTwitterRequestToken({ storedRequestToken, storedRequestTokenSecret, query }, auth);
      const { data } = requestTokenResponse;
      if (data.oauthAccessToken) {
        saveState('oauthAccessToken', data.oauthAccessToken)
        saveState('oauthAccessTokenSecret', data.oauthAccessTokenSecret)
        handleVerifyRequest(data.oauthAccessToken, data.oauthAccessTokenSecret, requestTokenResponse);
      }
    }

    if (query.oauth_token === storedRequestToken) {
      handleReqTokenRequest(userAuth.data);
    }
  }, [])

  return (
    <Box w="100%" h="100vh" mt="5%">
      <Flex justify="center" align="center" direction="column">
        <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color='blue.500'
          size='xl'
        />
        <Spacer />
        <Text>Authenticating your account...</Text>
      </Flex>
    </Box>
  )

}

export default AuthPage;