import { supabase } from '../methods/Supabase'; 

export const fetchGallery = async (userId) => {
  let images = [];

  const { data, error } = await supabase
    .storage
    .from('users_files')
    .list(userId + "/", {
      offset: 0,
      sortBy: { column: "name", order: "asc"}
    }); 

  if (data) {
    images = data;
  }

  return images;
};