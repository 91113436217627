import { createSlice } from '@reduxjs/toolkit';

export const todosSlice = createSlice({
    name: 'todos',
    initialState: [],
    reducers: {
        setTodos: (state, action) => {
          const todosArray = action.payload.data;
          state = [...todosArray];
          return state;
        },
        addNewTodo: (state, action) => {
          const newTodo = action.payload;
          return [...state, newTodo];
        },
        deleteTodo: (state, action) => {
          const newTodos = state.filter(todo => todo.id !== action.payload);
          state = [...newTodos];
          return state;
        }
    },
})

// this is for dispatch
export const { setTodos, addNewTodo, deleteTodo } = todosSlice.actions;

// this is for configureStore
export default todosSlice.reducer;
