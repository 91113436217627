import React from 'react';
import { Provider } from 'react-redux';
import store from './store';
import { createRoot } from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { theme } from './theme';
import { ChakraProvider, Flex, Text, Box } from '@chakra-ui/react'

import ErrorPage from "./error-page";
import App from './App';
import { NylasProvider } from '@nylas/nylas-react';
import reportWebVitals from './reportWebVitals';
import './index.css';
import Dashboard from "./pages/Dashboard/index.new.js";
import Social from "./pages/Social";
import Web from "./pages/Web";
import Office from "./pages/Office";
import Sales from "./pages/Sales";
import { Setup } from "./pages/Setup";
import { Skills } from "./pages/Skills";
import { Settings } from "./pages/Settings";
import { Media } from "./pages/Media";
import { Thankyou } from "./pages/Thankyou";
import { Chat } from "./pages/Chat";
import { Login } from "./pages/Login";
import { Signup } from "./pages/Signup";
import { Tools } from "./pages/Tools";
import { Topics } from "./pages/Topics";
import SocialTwitterAuth from "./pages/SocialTwitterAuth";
import mixpanel from 'mixpanel-browser';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import {
  BottomNavigation,
  BottomNavigationItem,
  BottomNavigationLabel,
} from "chakra-ui-bottom-navigation";


// Replace YOUR_TOKEN with your Project Token
mixpanel.init('1f5f7fcef26b562699d10dcbced9e7d6', { debug: true });

// Set this to a unique identifier for the user performing the event.
// eg: their ID in your database or their email address.

// mixpanel.identify(userVisitToken)

const container = document.getElementById('root');
const root = createRoot(container);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App mixpanel={mixpanel} />,
    errorElement: <ErrorPage mixpanel={mixpanel} />,
  },
  {
    path: "/login",
    element: <Login mixpanel={mixpanel} />,
    errorElement: <ErrorPage mixpanel={mixpanel} />,
  },
  {
    path: "/signup",
    element: <Signup mixpanel={mixpanel} />,
    errorElement: <ErrorPage mixpanel={mixpanel} />
  },
  {
    path: "/dashboard",
    element: <Dashboard mixpanel={mixpanel} />,
    errorElement: <ErrorPage mixpanel={mixpanel} />,
  },
  {
    path: "/social",
    element: <Social mixpanel={mixpanel} />,
    errorElement: <ErrorPage mixpanel={mixpanel} />,
  },
  {
    path: "/web",
    element: <Web mixpanel={mixpanel} />,
    errorElement: <ErrorPage mixpanel={mixpanel} />,
  },
  {
    path: "/office",
    element: <Office mixpanel={mixpanel} />,
    errorElement: <ErrorPage mixpanel={mixpanel} />,
  },
  {
    path: "/sales",
    element: <Sales mixpanel={mixpanel} />,
    errorElement: <ErrorPage mixpanel={mixpanel} />,
  },
  {
    path: "/twitter-auth",
    element: <SocialTwitterAuth mixpanel={mixpanel} />,
    errorElement: <ErrorPage mixpanel={mixpanel} />
  },
  {
    path: "/thank-you",
    element: <Thankyou mixpanel={mixpanel} />,
    errorElement: <ErrorPage mixpanel={mixpanel} />,
  },
  {
    path: "/setup/:un",
    element: <Setup mixpanel={mixpanel} />,
    errorElement: <ErrorPage mixpanel={mixpanel} />,
  },
  {
    path: "/skills",
    element: <Skills mixpanel={mixpanel} />,
    errorElement: <ErrorPage mixpanel={mixpanel} />,
  },
  {
    path: "/tools",
    element: <Tools mixpanel={mixpanel} />,
    errorElement: <ErrorPage mixpanel={mixpanel} />,
  },
  {
    path: "/topics",
    element: <Topics mixpanel={mixpanel} />,
    errorElement: <ErrorPage mixpanel={mixpanel} />,
  },
  {
    path: "/settings",
    element: <Settings mixpanel={mixpanel} />,
    errorElement: <ErrorPage mixpanel={mixpanel} />,
  },
  {
    path: "/media",
    element: <Media mixpanel={mixpanel} />,
    errorElement: <ErrorPage mixpanel={mixpanel} />,
  },
  {
    path: "/chat/:un",
    element: <Chat mixpanel={mixpanel} />,
    errorElement: <ErrorPage mixpanel={mixpanel} />,
  },
]);

root.render(
  <FlagsmithProvider options={{
    environmentID: process.env.REACT_APP_FF_KEY || 'hwj2BVDDZYsTMbsVvaoxoG',
  }} flagsmith={flagsmith}>
    <ChakraProvider theme={theme}>
      
      <NylasProvider serverBaseUrl={process.env.REACT_APP_SERVICE_URL}>
      <Provider store={store}>
        <Flex direction="column" minH="100vh">
          <Box flex="1">
            <RouterProvider router={router} />
          </Box>
          <Box mx={5}>
            <BottomNavigation
              value={1}

              colorScheme="green"
              variant="float"
              showLabel="if-active"
            >
              <BottomNavigationItem>

                <BottomNavigationLabel>
                  <Text className="pwr-footer__item pwr-footer-legal__notice">
                    ©️ 2022 Artificial Compute, Inc. | An Artificial Workforce Platform - patent pending
                  </Text>
                </BottomNavigationLabel>
              </BottomNavigationItem>
              <BottomNavigationItem value="value">

                <BottomNavigationLabel>
                  <Text ml={[0, 0, 2, 5]} className="hs-menu-item hs-menu-depth-1" role="none">
                    <a href="https://www.artificialcompute.com/privacy-policy" role="menuitem">Privacy Policy</a>
                  </Text>
                </BottomNavigationLabel>
              </BottomNavigationItem>
              <BottomNavigationItem>

                <BottomNavigationLabel>
                  <Text ml={[0, 0, 2, 5]} className="hs-menu-item hs-menu-depth-1 active active-branch" role="none">
                    <a href="https://www.artificialcompute.com/privacy-policy-0" role="menuitem">Terms of Service</a>
                  </Text>
                </BottomNavigationLabel>
              </BottomNavigationItem>
              <BottomNavigationItem>

                <BottomNavigationLabel>
                  <Text ml={[0, 0, 2, 5]} className="hs-menu-item hs-menu-depth-1" role="none">
                    <a href="https://www.artificialcompute.com/platform-guidelines" role="menuitem">Platform Guidelines</a>
                  </Text>
                </BottomNavigationLabel>
              </BottomNavigationItem>
              <BottomNavigationItem>

                <BottomNavigationLabel>
                  <Text ml={[0, 0, 2, 5]} className="hs-menu-item hs-menu-depth-1" role="none">
                    <a href="https://www.artificialcompute.com/cookie-policy" role="menuitem">Cookie Policy</a>
                  </Text>
                </BottomNavigationLabel>
              </BottomNavigationItem>
            </BottomNavigation>

          </Box>
        </Flex>
        </Provider>
        </NylasProvider>
    </ChakraProvider>
  </FlagsmithProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
