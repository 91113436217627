import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  Container,
  Heading,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/react'
import { Logo } from '../../components/Logos/Logo'
import { useNavigate } from "react-router-dom";
import SignIn from "../../components/Auth/signIn";

export const Login = () => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (auth.authenticated && auth?.profile?.industry !== null){
      navigate("/dashboard");
    } else if (auth.authenticated){
      if (auth.authenticated)
      navigate(`/setup/${auth?.session?.access_token}`);
    }
        
  })

  const handleSignupClick = () => window.location = "https://ac-waitlist.zapier.app";

  return (
    <Container
      maxW="lg"
      py={{
        base: '12',
        md: '24',
      }}
      px={{
        base: '0',
        sm: '8',
      }}
    >
      <Stack spacing="8">
        <Stack spacing="6">
          <Logo />
          <Stack
            spacing={{
              base: '2',
              md: '3',
            }}
            textAlign="center"
          >
            <Heading
              size={{
                base: 'xs',
                md: 'sm',
              }}
            >
              Thank you for being an Early Adopter. Create a New Account and Log In to the new app with the link below. 
            </Heading>
            <HStack spacing="1" justify="center">
            <Box>
              <Text color="primary">Don't have an account? See your inbox or email support@artificialcompute.com</Text>
              </Box>
              <Box>
              <Button onClick={handleSignupClick}><a>New App Sign Up</a></Button>
              </Box>
              
            </HStack>
          </Stack>
        </Stack>
        <Box
          py={{
            base: '0',
            sm: '8',
          }}
          px={{
            base: '4',
            sm: '10',
          }}
          bg={{
            base: 'transparent',
            sm: 'bg-surface',
          }}
          boxShadow={{
            base: 'none',
            sm: 'md',
          }}
          borderRadius={{
            base: 'none',
            sm: 'xl',
          }}
        >
          <Stack spacing="6">
            <h2>Please sign up to get access to the new Beta Version of the app </h2>
            <Button><a href="https://ac-waitlist.zapier.app/" >New App Log In</a></Button>
          </Stack>
        </Box>
      </Stack>
    </Container>
  )
}
