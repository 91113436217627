
const autoEditPost = async (payload) => {
    const reqHeaders = new Headers();
    reqHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify(payload);

    const requestOptions = {
        method: 'POST',
        headers: reqHeaders,
        body: raw
    }

    const result = await fetch(`${process.env.REACT_APP_SERVICE_URL}/v1/events`, requestOptions);
    return result.json();
}



export default {
    autoEditPost,
}