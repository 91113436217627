import { useState } from 'react';
import { useSelector } from "react-redux";
import {
  Icon,
  Menu,
  MenuButton,
  Text,
  IconButton,
  Box,
  Flex,
  Spacer,
  Heading
} from '@chakra-ui/react'
import { ChevronLeftIcon, HamburgerIcon } from '@chakra-ui/icons';
import { MdGroupWork } from 'react-icons/md';
import { ToolsConnectOveriew } from '../../components/ACTools';
import { Sidebar } from "../../components/ACSidebars";
import navigationData from "../../data/navigation";

export const Tools = () => {
  const auth = useSelector((state) => state.auth);
  const [closeSideBar, setCloseSideBar] = useState(false);

  if (!auth || auth.authenticated === false) {
    return (<>
      <h1>Shucks, something went wrong!</h1>
      <pre>{JSON.stringify(auth, 2)}</pre>
    </>);
  }

  return (
    <>
      <Box position="sticky" top="0" zIndex="1" bg="white" p={4}>
        <Flex>
          <Box><Icon as={MdGroupWork} w={8} h={8} color='black' /></Box>
          <Spacer />
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={closeSideBar ? <HamburgerIcon /> : <ChevronLeftIcon />}
              // varia nt={"ghost"}
              onClick={() => setCloseSideBar(closeSideBar ? false : true)}
            />
            {/* You can add MenuList and MenuItems here for dropdown options */}
          </Menu>
        </Flex>
      </Box>
      <Flex>
        <Box position="sticky" top="0" height="calc(100vh - 4rem)" width={'max-content'}
          display={closeSideBar ? 'none' : 'block'} >
          <Sidebar navigationData={navigationData} />
        </Box>
        <Box width={closeSideBar ? '100%' : '80%'} height="full" m={5}>
          <Heading>Tools & Access</Heading>
          <Text>Connect and/or view your social accounts.</Text>
          <Box
            as="section"
            bg="bg.surface"
            py={{
              base: '4',
              md: '8',
            }}
          >
            <ToolsConnectOveriew type={'realtime'} />
          </Box>
        </Box>
      </Flex>
    </>
  )
}
