import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import Gallery from 'react-photo-gallery';
import ImageGalleryEmptyState from './ImageGalleryEmptyState';

const ImageGallery = ({ media }) => {
  const [imageList, setImageList] = useState([]);

  useEffect(() => {
    if(media) setImageList(media)
  }, [media])

  if(isEmpty(imageList)) return <ImageGalleryEmptyState />

  return <Gallery photos={imageList} />
}

export default ImageGallery;