import { useSelector } from "react-redux";
import { useEffect, useState, useCallback } from "react";
import {
  Button,
  Container,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Logo } from '../Logos/Logo';
import { OAuthButtonGroup } from './OAuthButtonGroup';
import { NavLink, Link, useNavigate } from "react-router-dom";
import AddAuth from "../Auth/addAuth";
import LocalStorage from '../../methods/LocalStorage';
import jwtDecode from 'jwt-decode';


export const SignUpForm = (props) => {
  const [confirmMessage, setConfirmMessage] = useState(null);
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const { mixpanel } = props;
  const { saveState } = LocalStorage;

  const saveAuthToStorage = useCallback(async (uiuid, authPayload) => {
    await saveState(uiuid, authPayload);
    return true;
  }, []);

  useEffect(() => {
    if (auth.authenticated !== false) {
      const tokenObject = jwtDecode(auth?.session?.access_token)
      const authPayload = { data: { user: tokenObject, session: auth?.session }, id: auth?.session?.user?.id };
      saveAuthToStorage('ac_batptl', authPayload);
      setConfirmMessage(`Check your email to complete sign in.${auth?.email}.`);
      mixpanel.identify(auth?.email);
      navigate(`/setup/${auth?.session?.access_token}`);
    }
  }, [auth])

  const handleLoginClick = () => navigate("/login")

  return (
    <Container
      maxW="md"
      py={{
        base: '0',
        sm: '8',
      }}
      px={{
        base: '4',
        sm: '10',
      }}
      bg={{
        base: 'transparent',
        sm: 'bg-surface',
      }}
      boxShadow={{
        base: 'none',
        sm: 'md',
      }}
      borderRadius={{
        base: 'none',
        sm: 'xl',
      }}
    >
      <Stack spacing="8">
        <Stack spacing="6" align="center">
          <Logo />
          <Stack spacing="3" textAlign="center">
            {confirmMessage && (
              <>
                <Heading size="xs">Your Account is Created</Heading>
                <HStack justify="center" spacing="1">
                  <Text color="info">{confirmMessage}</Text>
                </HStack>
              </>
            )}
            {confirmMessage === null && (
              <>
                <Heading size="xs">Create an account</Heading>
                <HStack justify="center" spacing="1">
                  <Text color="muted">Already have an account?</Text>
                  <Button variant="link" colorScheme="teal" onClick={handleLoginClick}>
                    Log in
                  </Button>
                </HStack>
              </>)
            }
          </Stack>
        </Stack>
        <Stack spacing="6">
          {confirmMessage === null && (
            <AddAuth auth={auth} />
          )}

        </Stack>
      </Stack>
    </Container>
  )
}
