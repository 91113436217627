import { createSlice } from '@reduxjs/toolkit';

export const toolSlice = createSlice({
    name: 'tools',
    initialState: [],
    reducers: {
        setTools: (state, action) => {
          const toolsArray = action.payload.data;
          state = [...toolsArray];
          return state;
        },
        addNewTool: (state, action) => { 
          const newTool = action.payload;
          return [...state, newTool];
        },
        updateTools: (state, action) => { 

          let array = [...state];

          const newTool = action.payload;
          const idToFind = newTool.uiuid; 
          // Find the index of the object you want to replace
          const index = state.findIndex(obj => obj.uiuid === idToFind);
          const oldTool = index !== -1 ? state[index] : newTool;
          const newConfig = {...oldTool.config.toolAccess, ...newTool.config.toolAccess};
          newTool.config.toolAccess = newConfig;
          const mergedTool = {...oldTool, ...newTool};
          if (index !== -1) {
            // Replace the object
            array[index] = mergedTool;
          }

          return [...array];
        },
        deleteTool: (state, action) => {
          const newTools = state.filter(tool => tool.id !== action.payload);
          state = [...newTools];
          return state;
        }
    },
})

// this is for dispatch
export const { setTools, addNewTool, deleteTool, updateTools } = toolSlice.actions;

// this is for configureStore
export default toolSlice.reducer;
