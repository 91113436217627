import {
  Avatar,
  Box,
  Flex,
  HStack,
  InputGroup,
  InputLeftElement,
  Stack,
  StackDivider,
} from '@chakra-ui/react';
import {
  FiGrid,
  FiHelpCircle,
  FiSettings,
  FiImage,
  FiHome,
  FiLogOut,
  FiAlignRight
} from 'react-icons/fi';
import { SidebarButton } from './SidebarButton';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

const SkinnySidebar = () => {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleClearStorage = () => {
    localStorage.removeItem('oauthRequestTokenSecret');
    localStorage.removeItem('oauthRequestToken');
    localStorage.removeItem('oauthAccessTokenSecret');
    localStorage.removeItem('oauthAccessToken');
    localStorage.removeItem('ac_batptl');
    window.location.href = '.'
  };
  return (
    <Flex as="section" minH="92vh">
      <Stack
        flex="1"
        maxW={{
          base: 'full',
          sm: 'xs',
        }}
        py={{
          base: '6',
          sm: '8',
        }}
        px={{
          base: '1',
          sm: '6',
        }}
        bg="bg.surface"
        borderRightWidth="1px"
        justifyContent="space-between"
      >
        <Stack spacing="8">
          {/* <Logo alignSelf="start" /> */}
          <InputGroup>
            <InputLeftElement>
              {/* <Icon as={FiSearch} color="fg.muted" fontSize="lg" /> */}
            </InputLeftElement>
            {/* <Input placeholder="Search" /> */}
          </InputGroup>
          <Stack spacing="2">
            <SidebarButton leftIcon={<FiHome size='23px' />} label='Dashboard'
              onClick={() => navigate('/dashboard')}></SidebarButton>
            <Avatar boxSize="10" ml="5px" src='./avatars/PNG/32.png'
              name='Sanda Ae' onClick={() => navigate('/social')}/>
            <Avatar boxSize="10" ml="5px" src='./avatars/PNG/5.png'
            name='David Ae' onClick={() => navigate('/web')}/>  
            <Avatar boxSize="10" ml="5px" src='./avatars/PNG/4.png'
            name='Cesar Ae' onClick={() => navigate('/office')}/>
            <Avatar boxSize="10" ml="5px" src='./avatars/PNG/19.png'
            name='Jess Ae' onClick={() => navigate('/sales')}/>

            
          </Stack>
        </Stack>
        <Stack spacing="4" divider={<StackDivider />}>
          <Box />
          <Stack spacing="2">
            <SidebarButton leftIcon={<FiGrid size='23px' />} label='Tools'
              onClick={() => navigate('/tools')}></SidebarButton>
            {/* <DocumentCollapse /> */}
            <SidebarButton leftIcon={<FiImage size='23px' />} label='Media Gallery'
              onClick={() => navigate('/media')}></SidebarButton>
            {/* <SidebarButton leftIcon={<FiBookmark />}></SidebarButton> */}
            <SidebarButton leftIcon={<FiHelpCircle size='23px' />} label='Live Help'
              onClick={() => window.open('//usemotion.com/meet/ricklopez/live-support?d=15', "_blank")}></SidebarButton>
            <SidebarButton leftIcon={<FiSettings size='23px' />} label='Settings'
              onClick={() => navigate('/settings')}></SidebarButton>

            <SidebarButton leftIcon={<FiLogOut size='23px' />} label='Log Out'
              onClick={handleClearStorage}></SidebarButton>

          </Stack>
          <HStack spacing="3" justify="space-between">
            <HStack spacing="3">
              <Avatar boxSize="10" ml="5px" name={auth?.user_metadata.name} src={auth?.user_metadata.name?.photo} />
              {/* <Box>
              <Text textStyle="sm" fontWeight="medium">
                John Doe
              </Text>
              <Text textStyle="sm" color="fg.muted">
                john@chakra-ui.com
              </Text>
            </Box> */}
            </HStack>
            {/* <IconButton variant="tertiary" icon={<FiMoreVertical />} aria-label="Open Menu" /> */}
          </HStack>
        </Stack>
      </Stack>
    </Flex>
  )
}

export default SkinnySidebar;