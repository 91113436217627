import { createSlice } from '@reduxjs/toolkit';

export const ContentSlice = createSlice({
  name: 'content',
  initialState: [],
  reducers: {
    setContent: (state, action) => {
      const contentArray = action.payload.data;
      state = [...contentArray];
      return state;
    },
    addNewContent: (state, action) => {
      const newContent = action.payload;
      return [...state, newContent];
    },
    updateContent: (state, action) => {
      const { id, newContent } = action.payload;

      const index = state.findIndex(content => content.id === id);
      if (index !== -1) {
        state[index] = newContent;
      }
    },
    deleteContent: (state, action) => {
      const newContent = state.filter(content => content.id !== action.payload);
      state = [...newContent];
      return state;
    }
  },
})

// this is for dispatch
export const { setContent, addNewContent, updateContent, deleteContent } = ContentSlice.actions;

// this is for configureStore
export default ContentSlice.reducer;
