import { FaYoutube, FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import Threads from './components/Logos/Threads';

export const SocialProviders = [
  {
    name: 'instagram',
    colorScheme: 'purple',
    faIcon: <FaInstagram color="#833AB4" size={40}/>,
  },
  {
    name: 'facebook',
    colorScheme: 'facebook',
    faIcon: <FaFacebook color="#3b5998" size={40}/>,
  },
  {
    name: 'linkedIn',
    colorScheme: 'blue',
    faIcon: <FaLinkedin color="#0072b1" size={40}/>,
  },
  {
    name: 'youtube',
    colorScheme: 'red',
    faIcon: <FaYoutube color="#c4302b" size={40}/>,
  },
  {
    name: 'threads',
    colorScheme: 'blackAlpha',
    faIcon: <Threads />,
  },
]