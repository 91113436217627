import { useSelector } from "react-redux";
// import { useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Text,
  Input,
  InputGroup,
  InputLeftElement,
  useBreakpointValue,
  AlertIcon,
  Alert 
} from '@chakra-ui/react'
import { FiHelpCircle, FiMenu, FiSearch, FiSettings, FiHeart } from 'react-icons/fi';
import { MdGroupWork } from 'react-icons/md';
import { useNavigate } from "react-router-dom";
import LocalStorage from '../../methods/LocalStorage';

// import algoliasearch from 'algoliasearch/lite';
// import {
//   InstantSearch,
//   Configure,
//   Hits,
//   SearchBox,
// } from 'react-instantsearch-hooks-web';

// import './TopNavbar/TopNavbar.css'

// const searchClient = algoliasearch('D0HQNR0ORM', 'd213e7b526ddefad01a961b90339bb17');

export const TopNavbar = () => {
  const navigate = useNavigate();
  // const [showHits, setShowHits] = useState(false);
  const auth = useSelector((state) => state.auth);
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const { saveState, loadState } = LocalStorage;
  const twitterUser = loadState('twitterUser') || null;
  return (
    <Box as="section">
      <Box as="nav" bg="bg-surface" boxShadow="sm" px={5} pr={9}>
        <Flex justify="space-between" py={{ base: '3', lg: '4' }}>
          <HStack spacing="1" px={"2"}>
            <Icon as={MdGroupWork} w={8} h={8} color='black.500' />
          </HStack>
          <HStack spacing="1">
            <Text color={'dark.600'}>Thanks for being an Early Adopter!</Text> <FiHeart color={'red'} fontSize="1rem" />
          </HStack>
          <HStack spacing="4">
            <Avatar boxSize="10" name={auth?.user_metadata.name} src={twitterUser?.photo} />
          </HStack>

        </Flex>
        {isDesktop && (
          <>
            <Divider />
            <Flex justify="space-between" py={"2"}>
              <ButtonGroup variant={'blackAlpha'} spacing="1">
                <Button aria-current="page">{auth?.user_metadata.organization}</Button>
              </ButtonGroup>

            <div className="container">
            <Alert status='warning'>
                <AlertIcon />
                Early Beta: Since content is generated by AI, you might see some surprises, including inaccurate information. Make sure to check the facts and share feedback so we can learn and improve.
              </Alert> 
            </div>

              {/* 
               HIDDEN FOR FUTURE USE
              <div className="container">
                <InstantSearch searchClient={searchClient} indexName="my_first_index">
                  <div className="search-panel__results">
                    <SearchBox
                      placeholder="Search"
                      className="searchbox"
                      onFocus={() => setShowHits(true)} onBlur={() => setShowHits(false)}
                    />
                    <Configure hitsPerPage={8} />
                    {showHits ?
                      <Hits hitComponent={Hit} />
                      : null}
                  </div>
                </InstantSearch>
              </div> */} 

              {isDesktop ? (
                <HStack spacing="1">
            
                </HStack>
              ) : (
                <IconButton
                  variant="ghost"
                  icon={<FiMenu fontSize="1.25rem" />}
                  aria-label="Open Menu"
                />
              )}
            </Flex>
          </>
        )}
      </Box>
    </Box>
  )
}

// function Hit({ hit }) {
//   return (
//     <article>
//       <h1>Type: {hit.type}</h1>
//       <p>Industry: {hit.industry}</p>
//       <p>Topic: {hit.topic}</p>
//     </article>
//   );
// }