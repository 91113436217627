import { configureStore } from '@reduxjs/toolkit';
import skillReducer from '../features/skill/skillSlice';
import workItemReducer from '../features/workItem/workItemSlice';
import postReducer from '../features/post/postSlice';
import topicReducer from '../features/topic/topicSlice';
import authReducer from '../features/auth/authSlice';
import applicationReducer from '../features/application/applicationSlice';
import galleryReducer from "../features/gallery/gallerySlice";
import todosReducer from '../features/todos/todosSlice';
import eventsReducer from '../features/events/eventsSlice';
import contentReducer from '../features/content/contentSlice';
import blogReducer from '../features/blog/blogSlice';
import reactionReducer from '../features/reaction/reactionSlice';
import toolReducer from '../features/tool/toolSlice';

export default configureStore({
    reducer: {
        auth: authReducer,
        skills: skillReducer,
        workItems: workItemReducer,
        posts: postReducer,
        topics: topicReducer,
        blogs: blogReducer,
        todos: todosReducer,
        events: eventsReducer,
        tools: toolReducer,
        content: contentReducer,
        applications: applicationReducer,
        gallery: galleryReducer,
        reactions: reactionReducer
    },
});
