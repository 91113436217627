import React from 'react'
import Shepherd from 'shepherd.js';
import { useSelector } from 'react-redux';
import { useNavigate, Link } from "react-router-dom";
import { AppNavBar } from './components/Guest/AppNabBar';
import ContactForm from './components/Forms/ContactForm';
import { LandingDemoOverlay } from './components/Guest/LandingDemoOverlay';
import './App.css';
import {
  Box,
  Flex,
  Container,
  Card,
  Heading,
  Stack,
  Text,
  HStack,
  Button,
  VisuallyHidden,
  VisuallyHiddenInput,
  useBreakpointValue,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalFooter
} from "@chakra-ui/react";
import MicrosoftIcon from './components/Logos/MicrosoftLogo';
import OpenAILogo from './components/Logos/OpenAILogo';
import AWSLogo from './components/Logos/AWSLogo';
import AzureLogo from './components/Logos/AzureLogo';
import Threads from './components/Logos/Threads';

import 'shepherd.js/dist/css/shepherd.css';

export default function App() {
  const isDesktop = useBreakpointValue({ base: false, lg: true })
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure()

  const tour = new Shepherd.Tour({
    useModalOverlay: true,
    defaultStepOptions: {
      classes: 'shadow-md bg-purple-dark',
      scrollTo: true,
      exitOnEsc: true,
      cancelIcon: {
        enabled: true
      }
    }
  });

  tour.addStep({
    id: 'initial',
    text: `Welcome to Artificial Compute we make Artificial Employees for Small Business Owners! 😊 
    <br /><br />

    Guess what? You're one of the first to try out our AI Social Media Manager. Pretty cool, huh? <br /><br />
    
    Just keep in mind, we're still tweaking and polishing things up. So, by hopping on board now, we're counting on you to give us the lowdown with your feedback.<br/><br/> Oh, and of course, you gotta be okay with our terms of service, platform guidelines, and privacy policy below.
    <br /><br />
    Thank you for joining us. Let’s get started! 🚀`,
    scrollTo: {
      behavior: 'smooth',
      block: 'center'
    },
    buttons: [{
      text: 'Let\'s Go!',
      action: tour.next
    }]
  });

  tour.addStep({
    id: 'step1',
    text: 'Thanks for your interest. We are currently in a closed beta.',
    scrollTo: {
      behavior: 'smooth',
      block: 'center'
    },
    attachTo: {
      element: () => document.querySelector('.onboard-step-1'),
      on: 'bottom'
    },
    buttons: [{
      text: 'Next',
      action: tour.next
    }]
  });

  tour.addStep({
    id: 'step2',
    text: 'Log In if you already have an account.',
    scrollTo: {
      behavior: 'smooth',
      block: 'center'
    },
    attachTo: {
      element: '.onboard-step-2',
      on: 'bottom'
    },
    buttons: [
      {
        text: 'Back',
        action: tour.back
      },
      {
        text: 'Exit',
        action: tour.cancel
      }]
  });

  // tour.start();

  if (localStorage.getItem("seen_tour") == null) {
    tour.start();
    localStorage.setItem("seen_tour", "true");
  }

  return (
    <div className="App">
      <Card bg='linear-gradient(to bottom right, #FC466B 0%, #3F5EFB 100%)' p={4} m={4} color='white' maxH="1100px">
        <Box>
          <Stack spacing={3}>
            <AppNavBar auth={auth} />
          </Stack>
        </Box>

        <Box maxH={'80vh'}>
          {/*<img src={logo} className="App-logo" alt="logo" />*/}
          <Stack spacing={2}>
            <Heading as='h1' size='lg' color='black'>
            We make Artificial Employees for your Small Business
            </Heading>
            <Text color='black.900'>
              Artificial Compute gives you a simple way to create, train, and deploy an Artificial Workforce.
              <br /> Join our Early Adopter Program today.
            </Text>
            <Flex justify="center">
              <Stack direction='row' spacing={4} my={4} pb={5} align='center'>

                <Button className='onboard-step-1' colorScheme='black' onClick={() => navigate('/signup')}>
                  Sign Up
                </Button>
                <Button color='black' onClick={onOpen}>
                  Contact Us
                </Button>
              </Stack>
            </Flex>
            {!isDesktop && (
              <Stack direction='row' align='center'>
                <Text align='center' color={'black'}>Early Adopter Beta</Text>
              </Stack>
            )}
          </Stack>
        </Box>
        <Flex w="100%" justify="center">
          <LandingDemoOverlay />
        </Flex>
      </Card>
      <Card bg={'blackAlpha.200'} p={4} m={4} pt='180px' h={'max-content'}>
        <VisuallyHidden>This will be hidden</VisuallyHidden>
        <Flex justify="center">
          <Stack spacing={3} pt={{
            sm: '10vh',
            md: '10vh',
            lg: '5vh',
            xl: '20vh',
          }} pb={'2rem'}>
            <Text fontSize={'1.6rem'} color='black.900'>Powered By</Text>
            <Heading fontSize={'2rem'} size='md' color='black'>
              The Latest in AI Technology
            </Heading>
            <Flex m={1} justify="space-between">
              <OpenAILogo />
              <MicrosoftIcon />
              <AWSLogo />
            </Flex>
          </Stack>
        </Flex>

      </Card>

      <Card bg='black.800' p={4} m={4} color='white'>
        <VisuallyHidden>This will be hidden</VisuallyHidden>
        <Flex justify="center">
          <Stack spacing={3} py={'2rem'}>
          <Text fontSize={'1.6rem'} >Join Today</Text>
            <Text fontSize='md'>
              Artificial Compute is developing our public release. Currently, we are offering early access by invite only.
            </Text>
            <Flex justify="center">
              <Stack direction='row' spacing={4} my={4} pb={5} align='center'>
                <Button colorScheme='black' variant={'outline'} onClick={onOpen}>
                  Join the waiting list
                </Button>
              </Stack>
            </Flex>
          </Stack>
        </Flex>

      </Card>

      <footer className="App-footer">
        <Container>
          <div className="pwr-footer-legal">
            <Flex justify="center" p={10} direction={['column', 'column', 'row']}>
              <HStack spacing='24px'>
                <Text className="pwr-footer__item pwr-footer-legal__notice">
                  ©️ 2022 Artificial Compute, Inc. | An Artificial Employee Platform - patent pending
                </Text>
              </HStack>
              <Box mt={[4, 2, 0, 0]}>
                <Flex direction={['column', 'column', 'row']}>
                  <Text ml={[0, 0, 2, 5]} className="hs-menu-item hs-menu-depth-1" role="none">
                    <a href="https://www.artificialcompute.com/privacy-policy" role="menuitem">Privacy Policy</a>
                  </Text>
                  <Text ml={[0, 0, 2, 5]} className="hs-menu-item hs-menu-depth-1 active active-branch" role="none">
                    <a href="https://www.artificialcompute.com/privacy-policy-0" role="menuitem">Terms of Service</a>
                  </Text>
                  <Text ml={[0, 0, 2, 5]} className="hs-menu-item hs-menu-depth-1 active active-branch" role="none">
                    <a href="https://www.artificialcompute.com/platform-guidelines" role="menuitem">Platform Guidelines</a>
                  </Text>
                  <Text ml={[0, 0, 2, 5]} className="hs-menu-item hs-menu-depth-1" role="none">
                    <a href="https://www.artificialcompute.com/cookie-policy" role="menuitem">Cookie Policy</a>
                  </Text>
                </Flex>
              </Box>
            </Flex>
          </div>
        </Container>
      </footer>
      <Modal isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text fontSize="lg">Contact Us</Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ContactForm />
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}

